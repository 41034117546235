import { Logo } from "assets";
import { Stack, Typography } from "@mui/material";
import useTranslation from "i18n";

const NotInLocal = () => {
  const {i18next} = useTranslation()
  return (
    <Stack
      sx={{ width: "100%", padding: "20px", height: "80vh" }}
      alignItems="center"
      justifyContent="center"
      gap="30px"
    >
      <Logo width="100px" />
      <Typography variant="h5" sx={{ textAlign: "center", width: "100%" }}>
        {i18next.t('notInLocal')}
      </Typography>
    </Stack>
  );
};

export { NotInLocal };

import { Box, alpha, styled } from "@mui/material";

export const LoginContainer = styled(Box)(({theme}) => ({
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[50],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

export const FormContainer = styled(Box)(({theme}) => ({
    padding: '30px',
    width: '500px',
    borderRadius: '20px',
    border: `1px solid ${alpha(theme.palette.grey[50], 0.5)}`,
    backgroundColor: theme.palette.grey[800],
    boxShadow: '0px 3px 5px #00000040',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '100%',
        border: 'none',
        borderRadius: '0',
    }
}))
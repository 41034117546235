import { create } from "zustand";

interface useThemeModeProps {
  themeMode: "light" | "dark";
  // eslint-disable-next-line no-unused-vars
  setTheme: (theme: "light" | "dark") => void;
  setThemeInitial: (theme: "light" | "dark") => void;
}

const useThemeMode = create<useThemeModeProps>((set) => ({
  themeMode: "dark",
  setTheme: (theme: "light" | "dark") => {
    localStorage.setItem("storedTheme", JSON.stringify(theme));

    set(() => ({ themeMode: theme }));
  },

  setThemeInitial: (theme: "light" | "dark") =>
    set(() => ({ themeMode: theme })),
}));

export default useThemeMode;

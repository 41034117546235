import { styled } from "@mui/material";

export const LanguageContainer = styled("div")(({theme}) => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "100px",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[50]

}));

export const LabelContainer = styled("div")({
  position: "relative",
  width: "100%",
  border: "1px solid red",
});

export const Labels = styled("div")<{active: boolean}>(({active}) => ({
  position: "absolute",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  opacity: active ? 1 : 0,
  transition: active ? 'all 0.5s ease' : "all 0.5s  ease"
}));

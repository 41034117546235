import { Button, Stack, Typography } from "@mui/material";
import { Page, RHFPasswordTextField, RHFTextField } from "components";
import { EMAIL_REGEX, REQUIRED, VALID_EMAIL } from "constants/errors";
import { FormProvider } from "provider";
import { FieldValues, useForm } from "react-hook-form";

const Login = () => {
  const methods = useForm();

  const onSubmit = (fields: FieldValues) => {
    alert(`email: ${fields.email}`)
  }
  return (
    <Page title="Login">
      <Stack gap="10px">
        <Stack>
          <Typography variant="h3">Accedi</Typography>
          <Typography variant="body1">
            Inserisci le tue credenziali per entrare nell'area riservata.
          </Typography>
          <FormProvider
          onSubmit={methods.handleSubmit(onSubmit)}
            methods={methods}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingTop: "10px",
            }}
          >
            <RHFTextField name="email" label="Email" type="email"  required rules={{required: REQUIRED, pattern: {value: EMAIL_REGEX, message: VALID_EMAIL}}} />
            <RHFPasswordTextField name="password" label="Password" required rules={{required: REQUIRED}} />
            <Button variant="contained" color="primary" type="submit">
              ACCEDI
            </Button>
          </FormProvider>
        </Stack>
      </Stack>
    </Page>
  );
};

export default Login;

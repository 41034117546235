import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { Card, Page, PageContainer, TitleSection } from "components";
import { IFoodProps, FOODS, DOLCI } from "constants/food";
import { DOLCI_EN, FOODS_EN } from "constants/foodEn";
import { useFavorites } from "hooks/useFavorites";
import useTranslation from "i18n";
import { useEffect, useState } from "react";
import { useGlobalStore } from "stores/global";

const Favorite = () => {
  //hooks
  const { favorites, addFavorites, removeFavorites, showSnackbar } =
    useFavorites();
  //state
  const [mapFavorites, setMapFavorites] = useState<IFoodProps[]>([]);
  const { i18next } = useTranslation();
  const { activeLocale } = useGlobalStore();
  //effect
  useEffect(() => {
    const Allfood =
      activeLocale === "it" ? [...FOODS, ...DOLCI] : [...FOODS_EN, ...DOLCI_EN];
    if (favorites && favorites.length > 0) {
      const takeFavorites: (IFoodProps | undefined)[] = favorites.map(
        (el: number) => Allfood.find((f: IFoodProps) => f.id === el)
      );
      if (takeFavorites.length > 0) {
        setMapFavorites(takeFavorites as IFoodProps[]);
      }
      return;
    }

    if (!favorites || favorites.length === 0) {
      setMapFavorites([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites, favorites.length]);

  //function
  const handleFavorite = (id: number, name: string) => {
    const isPresent = favorites.find((el) => el === id);
    if (Boolean(isPresent)) {
      showSnackbar(`${name} rimosso dai preferiti`);
      removeFavorites(id);
      return;
    }
    if (!Boolean(isPresent)) {
      showSnackbar(`${name} aggiunto ai preferiti`);
      addFavorites(id);
      return;
    }
  };

  const isPresentInFavorites = (id: number) => {
    const isPresent = favorites.find((el) => el === id);
    return Boolean(isPresent);
  };
  return (
    <Page title={i18next.t("favoriti")}>
      <PageContainer>
        <TitleSection title={i18next.t("favoriti")} />
        <Stack gap="15px">
          {mapFavorites && mapFavorites.length > 0 ? (
            mapFavorites.map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                key={`cont${index}`}
                showCategory={true}
                clickFavorites={handleFavorite}
                isFavorite={isPresentInFavorites(el.id)}
                showGlutenFree
              />
            ))
          ) : (
            <Typography variant="caption" textAlign="center">
              Ancora nessun elemento tra i preferiti
            </Typography>
          )}
        </Stack>
      </PageContainer>
    </Page>
  );
};

export default Favorite;

/* eslint-disable no-unused-vars */
import { Localization } from "@mui/material/locale";
export interface ILang {
  label: string;
  value: Locales;
  systemValue: Localization;
  icon: string;
}

export enum Locales {
  IT = "it",
  EN = "en",
  DE = "de"
}

export interface ICustomi18n {
  [key: string]: any;
}

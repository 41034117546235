export interface IFoodProps {
  id: number;
  label: string;
  secondLabel?: string;
  singlePrice: boolean;
  methods?: string;
  priceNum?: number;
  priceWord?: string;
  allergenes?: number[];
  ingredients?: number[];
  pricesNum?: number[];
  pricesWord?: string[];
  pricesIconRef?: string[];
  pricesUnit?: string[];
  pricesIconSize?: string[];
  searchWord?: number[];
  category?: number;
  advised?: string;
  advisedIcon?: string;
  glutenFree?: boolean;
}

export interface IIngredientsProps {
  id: number;
  label: string;
  icon: string;
}

export interface ISearchWord {
  value: string;
  label: string;
}

export const ENTREE: IFoodProps[] = [
  {
    id: 3,
    label: "Calamari fritti",
    singlePrice: true,
    priceNum: 10,
    ingredients: [57, 58],
    priceWord: "dieci",
    allergenes: [1, 3, 4, 9, 10, 11],
    searchWord: [2, 4],
    category: 1,
    advised: "Vino bianco strutturato o Vino bianco spumantizzato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 1,
    label: "Burrata fritta in crosta di pizza",
    singlePrice: true,
    priceNum: 8,
    priceWord: "sette",
    allergenes: [1, 7],
    searchWord: [39],
    category: 1,
    advised: "Vino bianco semiaromatico",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 5,
    label: "Tagliere di salumi e formaggi nostrani",
    secondLabel: "Servito con montanarina fritta",
    singlePrice: true,
    priceNum: 8,
    priceWord: "dieci",
    allergenes: [1, 7, 8, 12],
    searchWord: [6, 7],
    category: 1,
    advised: "Vino rosso strutturato o Birra rossa",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 3,
    label: "Patatine",
    singlePrice: true,
    priceNum: 5,
    priceWord: "cinque",
    searchWord: [21],
    category: 1,
    advised: "Vino bianco strutturato o Vino bianco spumantizzato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 4,
    label: "Spicy Dippers",
    singlePrice: true,
    priceNum: 5.5,
    priceWord: "cinque e cinquanta",
    category: 1,
    advised: "Vino bianco strutturato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 37,
    label: "Verdurine in tempura",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [1, 3],
    category: 5,
  },
  {
    id: 38,
    label: "Diavoletti con salsa piccante",
    singlePrice: true,
    priceNum: 5,
    priceWord: "cinque e cinquanta",
    allergenes: [1, 12],
    category: 5,
  },
  {
    id: 39,
    label: "Bandidos di pollo",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [1, 3, 4, 6, 7, 9, 10, 14],
    searchWord: [38],
    category: 5,
  },
  {
    id: 40,
    label: "Crema fritta",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [1, 3, 7],
    category: 5,
  },
  {
    id: 6,
    label: "Olive Ascolane al tartufo",
    singlePrice: true,
    priceNum: 7,
    priceWord: "sette",
    allergenes: [1, 3, 4, 6, 7, 9, 10, 12, 14],
    category: 1,
    advised: "Vino bianco aromatico",
    advisedIcon: "wineGlassRed",
  },
  {
    id: 7,
    label: "Crocché di patate cotto e mozzarella",
    singlePrice: true,
    priceNum: 7,
    priceWord: "sette",
    allergenes: [1, 3, 4, 7, 9],
    ingredients: [],
    searchWord: [7],
    category: 1,
    advised: "Vino rosso strutturato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 88,
    label: "Gran fritto Mamá",
    secondLabel:
      "Crocché, creme, olive, verdurine in tempura, patatine e diavoletti",
    singlePrice: true,
    priceNum: 13,
    priceWord: "tredici",
    allergenes: [1, 3, 4, 6, 7, 9, 10, 12, 14],
    category: 1,
    advised: "Vino rosso strutturato",
    advisedIcon: "wineGlassWhite",
  },
];

export const TARTARE: IFoodProps[] = [
  {
    id: 8,
    label: "Salmone",
    singlePrice: true,
    priceNum: 12,
    priceWord: "dodici",
    allergenes: [4, 7, 12],
    ingredients: [91, 92, 93],
    searchWord: [2, 10],
    category: 1,
    advised: "Vino bianco aromatico, semi aromatico o prosecco",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 9,
    label: "Tonno",
    singlePrice: true,
    priceNum: 12,
    priceWord: "dodici",
    allergenes: [4, 6, 7, 11],
    ingredients: [32, 95, 82],
    searchWord: [2, 11],
    category: 1,
    advised: "Vino spumantizzato o Vino bianco aromatico",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 10,
    label: "Gambero Imperiale",
    singlePrice: true,
    priceNum: 12,
    priceWord: "dodici",
    allergenes: [2, 7, 12],
    ingredients: [90, 32, 96],
    searchWord: [2, 12],
    category: 1,
    advised: "Vino spumantizzato o Vino bianco aromatico",
    advisedIcon: "wineGlassWhite",
  },

  {
    id: 11,
    label: "Scottona Marchigiana",
    singlePrice: true,
    priceNum: 12,
    priceWord: "dodici",
    allergenes: [1, 3, 6, 7, 10, 11, 12, 13],
    ingredients: [23, 97, 45],
    searchWord: [27],
    category: 1,
    advised: "Vino bianco strutturato",
    advisedIcon: "wineGlassWhite",
  },
];

export const PIZZE_LE_TRADIZIONALI: IFoodProps[] = [
  {
    id: 12,
    label: "Margherita",
    singlePrice: true,
    priceNum: 8,
    priceWord: "otto",
    allergenes: [1, 7],
    ingredients: [1, 2, 3, 4],
    searchWord: [13, 14, 15],
    category: 2,
    advised: "Vino bianco o Vino rosato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 92,
    label: "Bufalina",
    singlePrice: true,
    priceNum: 9,
    priceWord: "nove",
    allergenes: [1, 7],
    ingredients: [1, 6, 112, 4],
    searchWord: [12, 14, 15],
    category: 2,
  },
  {
    id: 13,
    label: "Sofì",
    singlePrice: true,
    priceNum: 9,
    priceWord: "nove",
    allergenes: [1, 7],
    ingredients: [5, 1, 2, 3, 4],
    searchWord: [14, 13, 15],
    category: 2,
    advised: "Vino spumantizzato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 14,
    label: "Sbagliata",
    singlePrice: true,
    priceNum: 11,
    priceWord: "undici",
    allergenes: [1, 7, 9],
    ingredients: [102, 113, 7],
    searchWord: [14, 13, 16, 39],
    category: 2,
    advised: "Vino bianco o Vino Rosato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 15,
    label: "Pomod'oro",
    singlePrice: true,
    priceNum: 10,
    priceWord: "dieci",
    allergenes: [1, 7, 9],
    ingredients: [8, 6, 114],
    searchWord: [14, 13, 16],
    category: 2,
    advised: "Vino bianco media struttura o Bollicina",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 16,
    label: "Marinara",
    singlePrice: true,
    priceNum: 12,
    priceWord: "dodici",
    allergenes: [1, 4, 7, 12],
    ingredients: [1, 6, 9, 10, 11],
    searchWord: [14, 13, 16, 17, 3],
    category: 2,
    advised: "Vino bianco strutturato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 17,
    label: "Capriccio",
    singlePrice: true,
    priceNum: 13,
    priceWord: "tredici",
    allergenes: [1, 7, 12],
    ingredients: [115, 2, 13, 116, 10],
    searchWord: [18, 13, 15, 19, 20, 17],
    category: 2,
    advised: "Vino rosso media struttura o Vino rosato",
    advisedIcon: "wineGlassRed",
  },
  {
    id: 18,
    label: "Un Viaggio a Praga",
    singlePrice: true,
    priceNum: 11,
    priceWord: "undici",
    allergenes: [1, 3, 7, 8, 10, 12],
    ingredients: [117, 2, 118, 119],
    searchWord: [21, 15, 13, 44, 45],
    category: 2,
    advised: "Birra rossa",
    advisedIcon: "beerGlass",
  },
  {
    id: 26,
    label: "4 formaggi 2.0",
    singlePrice: true,
    priceNum: 11,
    priceWord: "undici",
    allergenes: [1, 7, 12],
    ingredients: [2, 120, 38, 45, 30, 48],
    searchWord: [13, 15, 31, 32],
    category: 2,
    advised: "Vino spumantizzato o Vino rosso strutturato o Birra rossa",
    advisedIcon: "wineGlassWhite",
  },
];

export const PIZZE_SENZA_GLUTINE: IFoodProps[] = [
  {
    id: 93,
    label: "Margherita",
    singlePrice: true,
    priceNum: 10,
    priceWord: "dieci",
    allergenes: [ 6, 7, 8, 10, 11],
    ingredients: [1, 2, 3, 4],
    searchWord: [13, 14, 15],
    category: 2,
    advised: "Vino bianco o Vino rosato",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 94,
    label: "Bufalina",
    singlePrice: true,
    priceNum: 11,
    priceWord: "undici",
    allergenes: [ 6, 7, 8, 10, 11],
    ingredients: [1, 6, 112, 4],
    searchWord: [12, 14, 15],
    category: 2,
    glutenFree: true,
  },
  {
    id: 95,
    label: "Sbagliata",
    singlePrice: true,
    priceNum: 13,
    priceWord: "tredici",
    allergenes: [ 6, 7, 8, 9, 10, 11],
    ingredients: [102, 113, 7],
    searchWord: [14, 13, 16, 39],
    category: 2,
    advised: "Vino bianco o Vino Rosato",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 96,
    label: "Pomod'oro",
    singlePrice: true,
    priceNum: 11,
    priceWord: "undici",
    allergenes: [ 6, 7, 8, 9, 10, 11],
    ingredients: [8, 6, 7],
    searchWord: [14, 13, 16],
    category: 2,
    advised: "Vino bianco media struttura o Bollicina",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 97,
    label: "Marinara",
    singlePrice: true,
    priceNum: 14,
    priceWord: "quattordici",
    allergenes: [ 4, 6, 7, 8, 10, 11, 12],
    ingredients: [1, 6, 9, 10, 11],
    searchWord: [14, 13, 16, 17, 3],
    category: 2,
    advised: "Vino bianco strutturato",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 99,
    label: "Costiera",
    singlePrice: true,
    priceNum: 16,
    priceWord: "sedici",
    allergenes: [ 4, 6, 7, 8, 10, 11],
    ingredients: [8, 6, 27, 28, 29, 30],
    searchWord: [14, 13, 16, 11],
    category: 2,
    advised: "Vino spumantizzato o Vino bianco aromatico",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 100,
    label: "La Preferita",
    singlePrice: true,
    priceNum: 14,
    priceWord: "quattordici",
    allergenes: [ 6, 7, 8, 10, 11],
    ingredients: [49, 2, 50, 32, 51],
    searchWord: [21, 13, 15, 19],
    category: 2,
    advised: "Vino bianco spumantizzato o Vino rosato",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 101,
    label: "Mortazza",
    singlePrice: true,
    priceNum: 15,
    priceWord: "quindici",
    allergenes: [ 6, 7, 8,10, 11, 12],
    ingredients: [2, 37, 32, 98],
    searchWord: [21, 13, 15, 29, 35],
    category: 2,
    advised: "Vino bianco spumantizzato",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 98,
    label: "4 formaggi 2.0",
    singlePrice: true,
    priceNum: 13,
    priceWord: "tredici",
    allergenes: [ 6, 7, 8, 10, 11, 12],
    ingredients: [2, 120, 126, 45, 30, 48],
    searchWord: [13, 15, 31, 32],
    category: 2,
    advised: "Vino spumantizzato o Vino rosso strutturato o Birra rossa",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
];

export const PIZZE_LE_CONTEMPORANEE: IFoodProps[] = [
  {
    id: 401,
    label: "Equinozio d'autunno",
    singlePrice: true,
    priceNum: 15,
    priceWord: "quindici",
    allergenes: [1,7, 8, 12],
    ingredients: [132, 133, 134, 42],
    category: 2
  },
  {
    id:400,
    label: 'Che Ficata',
    singlePrice: true,
    priceNum: 15,
    priceWord: "quindici",
    allergenes: [1, 5, 7, 8, 11, 12],
    ingredients: [129, 126, 130, 131],
    category:2,
  },
  {
    id: 89,
    label: "Nduja Assassina",
    singlePrice: true,
    priceNum: 12,
    priceWord: "dodici",
    allergenes: [1, 7, 12],
    ingredients: [102, 32, 103, 104, 105],
    searchWord: [41, 17],
    category: 2,
  },
  {
    id: 90,
    label: "CarboNera",
    singlePrice: true,
    priceNum: 14,
    priceWord: "quattordici",
    allergenes: [1, 3, 7, 12],
    ingredients: [2, 125, 38, 42, 96, 30,],
    searchWord: [41, 17],
    category: 2,
  },
  {
    id: 91,
    label: "Salmo...Nel Bosco",
    singlePrice: true,
    priceNum: 15,
    priceWord: "quindici",
    allergenes: [1, 4, 7, 11, 12],
    ingredients: [106, 83, 107, 108, 32, 109],
    searchWord: [10, 42],
    category: 2,
  },
  {
    id: 22,
    label: "Costiera",
    singlePrice: true,
    priceNum: 14,
    priceWord: "quattordici",
    allergenes: [1, 4, 7],
    ingredients: [8, 6, 27, 28, 29, 30],
    searchWord: [14, 13, 16, 11],
    category: 2,
    advised: "Vino spumantizzato o Vino bianco aromatico",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 23,
    label: "Stasera mi butto",
    singlePrice: true,
    priceNum: 15,
    priceWord: "quindici",
    allergenes: [1, 3, 4, 6, 7, 10, 12, 14],
    ingredients: [31, 32, 100, 101],
    searchWord: [4, 28, 37],
    category: 2,
    advised: "Vino bianco strutturato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 24,
    label: "Mortazza",
    singlePrice: true,
    priceNum: 12,
    priceWord: "tredici",
    allergenes: [1, 7, 8, 12],
    ingredients: [2, 37, 38, 98],
    searchWord: [21, 13, 15, 29, 35],
    category: 2,
    advised: "Vino bianco spumantizzato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 25,
    label: "Mamà",
    singlePrice: true,
    priceNum: 16,
    priceWord: "sedici",
    allergenes: [1, 2, 7, 8, 12],
    ingredients: [39, 32, 40, 41, 42],
    searchWord: [30, 21, 12, 34],
    category: 2,
    advised: "Vino rosato spumantizzato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 20,
    label: "Merenda del Pastore",
    singlePrice: true,
    priceNum: 13,
    priceWord: "tredici",
    allergenes: [1, 3, 6, 7, 8, 10, 11, 12, 13],
    ingredients: [2, 21, 22, 99, 94],
    searchWord: [13, 15, 24],
    category: 2,
    advised: "Vino rosso locale o Vino bianco strutturato",
    advisedIcon: "wineGlassRed",
  },
  {
    id: 27,
    label: "La Preferita",
    singlePrice: true,
    priceNum: 12,
    priceWord: "dodici",
    allergenes: [1, 7],
    ingredients: [49, 2, 50, 32, 51],
    searchWord: [21, 13, 15, 19],
    category: 2,
    advised: "Vino bianco spumantizzato o Vino rosato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 19,
    label: "Ortogolosa",
    singlePrice: true,
    priceNum: 12,
    priceWord: "dodici",
    allergenes: [1, 7, 8, 12],
    ingredients: [2, 49, 22, 110, 104, 111, 3],
    searchWord: [15, 43, 14, 17],
    category: 2,
    advised: "Vino rosso strutturato",
    advisedIcon: "wineGlassRed",
  },

  {
    id: 21,
    label: "Porca Norma",
    singlePrice: true,
    priceNum: 11,
    priceWord: "undici",
    allergenes: [1, 7, 9],
    ingredients: [1, 2, 24, 25, 99, 7],
    searchWord: [13, 14, 15, 24, 7, 25, 26, 27],
    category: 2,
    advised: "Birra rossa, Vino rosso, Vino rosato o Bollicina",
    advisedIcon: "beerGlass",
  },

  // {
  //   id: 28,
  //   label: "Buonconsiglio",
  //   singlePrice: true,
  //   priceNum: 14,
  //   priceWord: "quattordici",
  //   allergenes: [1, 7],
  //   ingredients: [32, 52, 53, 45, 54],
  //   searchWord: [7],
  //   category: 2,
  //   advised: "Vino bianco aromatico",
  //   advisedIcon: "wineGlassWhite",
  // },
];

export const SEI_SPICCHI: IFoodProps[] = [
  {
    id: 102,
    label: "Alto Adige",
    singlePrice: true,
    priceNum: 16,
    priceWord: "sedici",
    allergenes: [1, 7, 9, 12],
    ingredients: [52, 32, 7, 54],
    searchWord: [8],
    category: 3,
  },
  {
    id: 29,
    label: "Red Salmo",
    singlePrice: true,
    priceNum: 17,
    priceWord: "diciassette",
    allergenes: [1, 3, 4, 7, 8, 10, 12],
    ingredients: [121, 73, 101, 66],
    searchWord: [33, 10, 27],
    category: 3,
    advised: "Vino bianco aromatico o Vino bianco semiaromatico o Bollicina",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 30,
    label: "Tunataki",
    singlePrice: true,
    priceNum: 18,
    priceWord: "diciotto",
    allergenes: [1, 3, 4, 6, 7, 8, 9, 10, 11, 12],
    ingredients: [32, 76, 108, 77, 122],
    searchWord: [11],
    category: 3,
    advised: "Vino bianco spumantizzato o Vino bianco aromatico",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 31,
    label: "Squiddy",
    singlePrice: true,
    priceNum: 17,
    priceWord: "diciassette",
    allergenes: [1, 4, 7, 12, 14],
    ingredients: [38, 79, 96, 110],
    searchWord: [9, 34, 35],
    category: 3,
    advised: "Vino bianco strutturato o Bollicina",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 32,
    label: "La Marchigiana",
    singlePrice: true,
    priceNum: 18,
    priceWord: "diciotto",
    allergenes: [1, 3, 7, 12],
    ingredients: [81, 123, 42],
    category: 3,
    advised: "Vino bianco strutturato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 33,
    label: "Pork Super-crusty",
    singlePrice: true,
    priceNum: 16,
    priceWord: "sedici",
    allergenes: [1, 7, 12],
    ingredients: [86, 124, 115],
    searchWord: [36, 23],
    category: 3,
    advised: "Vino rosso o Birra rossa",
    advisedIcon: "wineGlassRed",
  },
];

export const FRITTI: IFoodProps[] = [
  {
    id: 35,
    label: "Patatine Fritte",
    singlePrice: true,
    priceNum: 4,
    priceWord: "quattro",
    allergenes: [],
    searchWord: [21],
    category: 5,
  },
  {
    id: 36,
    label: "Dippers speziate",
    singlePrice: true,
    priceNum: 5,
    priceWord: "cinque",
    allergenes: [],
    searchWord: [21],
    category: 5,
  },
  {
    id: 37,
    label: "Verdurine in tempura",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [1, 3],
    category: 5,
  },
  {
    id: 38,
    label: "Diavoletti con salsa piccante",
    singlePrice: true,
    priceNum: 5,
    priceWord: "cinque",
    allergenes: [1],
    category: 5,
  },
  {
    id: 39,
    label: "Bandidos di pollo",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [1, 3],
    searchWord: [38],
    category: 5,
  },
  {
    id: 40,
    label: "Crema fritta",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [1, 3, 7],
    category: 5,
  },
];

export const DOLCI: IFoodProps[] = [
  {
    id: 41,
    label: "Panettone artigianale della casa al cioccolato e ai frutti esotici",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [1, 3, 5, 7, 8],
    advised: "Casalis Douhet passito 900",
    advisedIcon: "wineGlassWhite",
    category: 6,
  },
  {
    id: 41,
    label: "Cheesecake",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [1, 3, 7],
    advised: "Casalis Douhet passito 900",
    advisedIcon: "wineGlassWhite",
    category: 6,
  },
  // {
  //   id: 44,
  //   label: "Semifreddo al caramello salato",
  //   singlePrice: true,
  //   priceNum: 6,
  //   priceWord: "sei",
  //   allergenes: [5, 7],
  //   advised: "Casalis Douhet passito 900",
  //   advisedIcon: "wineGlassWhite",
  //   category: 6,
  // },
  // {
  //   id: 34,
  //   label: "Fondente al Cocco",
  //   singlePrice: true,
  //   priceNum: 6,
  //   priceWord: "sei",
  //   allergenes: [7, 8, 12],
  //   advised: "Casalis Douhet passito 900",
  //   advisedIcon: "wineGlassWhite",
  //   category: 6,
  // },
  {
    id: 42,
    label: "Sferamisù",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [1, 3, 7],
    advised: "Casalis Douhet passito 900",
    advisedIcon: "wineGlassWhite",
    category: 6,
  },
  // {
  //   id: 43,
  //   label: "Sorbetto al caffè",
  //   singlePrice: true,
  //   priceNum: 3,
  //   priceWord: "tre",
  //   allergenes: [7],
  //   advised: "Casalis Douhet passito 900",
  //   advisedIcon: "wineGlassWhite",
  //   category: 6,
  // },

  {
    id: 45,
    label: "Tortino al cioccolato con cuore fondente",
    singlePrice: true,
    priceNum: 6,
    priceWord: "sei",
    allergenes: [3, 6, 7, 8],
    advised: "Casalis Douhet passito 900",
    advisedIcon: "wineGlassWhite",
    category: 6,
  },
];

export const BOLLICINE: IFoodProps[] = [
  {
    id: 120,
    label: `"Vernaccianera" Spumante Vernaccia di Serrapetrona DOCG Secca`,
    methods: "Classico",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [23],
    pricesWord: ["ventitre"],
    pricesIconRef: ["wineBottle", "spumanteGlass"],
  },
  {
    id: 46,
    label: "Prosecco Superiore DOCG Extra Dry Villa Giustiniani",
    methods: "Classico",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [18],
    pricesWord: ["diciotto"],
    pricesIconRef: ["wineBottle", "spumanteGlass"],
  },
  {
    id: 103,
    label: "Centanni Brut",
    secondLabel: "Passerina BIO",
    methods: "Charmat",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [23],
    pricesWord: ["ventitre"],
    pricesIconRef: ["wineBottle", "spumanteGlass"],
  },
  {
    id: 104,
    label: "Luigi Ghislieri Brut",
    secondLabel: "Verdicchio di Jesi BIO",
    methods: "Classico",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [29],
    pricesWord: ["ventinove"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 105,
    label: 'Gobbi e Mannocchi "Osso"',
    secondLabel: "Rosato Extra Brut",
    methods: "Ancestrale",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [27],
    pricesWord: ["ventisette"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 106,
    label: 'Tavignano "Il Pestifero"',
    secondLabel: "Bianco Marche BIO",
    methods: "Non Filtrato",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [22],
    pricesWord: ["ventidue"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 107,
    label: "Saputi Ribona",
    secondLabel: "Doc Extra Dry",
    methods: "Charmat",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [21],
    pricesWord: ["ventuno"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 108,
    label: "Arthur Metz",
    secondLabel: `Cremant D'Alsace Brut "Perle Noire"`,
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [30],
    pricesWord: ["trenta"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 109,
    label: "Bruno Ceci",
    secondLabel: `Lambrusco Spumante brut`,
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [23],
    pricesWord: ["ventitre"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 52,
    label: "Erbaluce Pas Dose Masilé DOCG",
    methods: "Classico",
    singlePrice: false,
    pricesNum: [40],
    pricesWord: ["quaranta"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 110,
    label: "Forchir Ribolla Gialla",
    secondLabel: `Brut Nature`,
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [24],
    pricesWord: ["ventiquattro"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 111,
    label: "Corteaura Franciacorta",
    secondLabel: `Brut DOCG`,
    methods: 'Classico',
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [36],
    pricesWord: ["trentasei"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 54,
    label: "La Montina Franciacorta Extra Brut Rosé Millesimato",
    methods: "Classico",
    singlePrice: false,
    pricesNum: [45],
    pricesWord: ["quarantacinque"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
];

export const CHAMPAGNE: IFoodProps[] = [
  {
    id: 55,
    label: "Champagne Jean de la Fontaine brut L'Eloquente",
    secondLabel: "73% Pinot Mounier - 16% Chardonnay - 11% Pinot Nero",
    methods: "Classico",
    singlePrice: false,
    pricesNum: [50],
    pricesIconSize: ["35px", "30px"],
    pricesWord: ["cinquanta"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 56,
    label: "Champagne Gamet Rive Droite Blanc de noirs Vallée de la Marne",
    secondLabel: "60% Pinot Mounier - 40% Pinot Nero",
    methods: "Classico",
    singlePrice: false,
    pricesNum: [60],
    pricesWord: ["sessanta"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 57,
    label: "Champagne Brice Heritage brut Grande Cru Bouzy",
    secondLabel: "30% Chardonnay - 70% Pinot Nero",
    methods: "Classico",
    singlePrice: false,
    pricesNum: [60],
    pricesWord: ["sessanta"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },

];

export const VINI_BIANCHI: IFoodProps[] = [
  {
    id: 59,
    label: `Tavignano Verdicchio dei Castelli di Jesi Sup. Doc "Villa Torre"`,
    singlePrice: false,
    pricesNum: [19],
    pricesIconSize: ["35px"],
    pricesWord: ["diciannove"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 60,
    label: `Borgo Paglianetto Verdicchio di Matelica DOC "Terravignata"`,
    singlePrice: false,
    pricesNum: [18],
    pricesWord: ["diciotto"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 61,
    label:  `Centanni Passerina DOCG "Cimula"`,
    singlePrice: false,
    pricesNum: [20],
    pricesWord: ["venti"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 62,
    label: `Collevite Pecorino Falerio DOCG BIO "Villa Piatti" `,
    singlePrice: false,
    pricesNum: [21],
    pricesWord: ["ventuno"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle" ],
  },
  {
    id: 63,
    label: "Girlan Chardonnay Alto Adige DOC",
    singlePrice: false,
    pricesNum: [24],
    pricesWord: ["ventiquattro"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 64,
    label: "Girlan Sauvignon INDRA",
    singlePrice: false,
    pricesNum: [26],
    pricesWord: ["ventisei"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 65,
    label: `Erste&Neue Gewürztraminer DOC`,
    singlePrice: false,
    pricesNum: [26],
    pricesWord: ["ventisei"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 112,
    label: `Abtei Himmerod Riesling Trocken "Vecchie Vigne" Mosella`,
    singlePrice: false,
    pricesNum: [24],
    pricesWord: ["ventiquattro"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
];

export const VINI_ROSSI: IFoodProps[] = [
  {
    id: 66,
    label: `Vicari Lacrima di Morro D'Alba "Da sempre"`,
    singlePrice: false,
    pricesNum: [19],
    pricesWord: ["diciannove"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 67,
    label: "Lanari Rosso Conero DOC",
    singlePrice: false,
    pricesNum: [21],
    pricesWord: ["ventuno"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 68,
    label: `Tenuta S. Antonio "Monti Garbi" Valpolicella Ripasso Superiore DOC`,
    singlePrice: false,
    pricesNum: [35],
    pricesWord: ["trentacinque"],
    pricesIconSize: ["35px", "25px"],
    pricesIconRef: ["wineBottle", "wineGlassRed"],
  },
  {
    id: 113,
    label: `Rosso Dolomiti IGT "T Rosso"`,
    singlePrice: false,
    pricesNum: [18],
    pricesWord: ["diciotto"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 114,
    label: `Centanni "Floralia" Montepulciano Rosato Marche`,
    singlePrice: false,
    pricesNum: [20],
    pricesWord: ["venti"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
];

export const BEVANDE: IFoodProps[] = [
  {
    id: 69,
    label: "Acqua Naturale/Frizzante",
    singlePrice: false,
    pricesNum: [2],
    pricesWord: ["due"],
    pricesUnit: ["75 cl"],
    pricesIconSize: ["20px"],

    pricesIconRef: ["waterIcon"],
  },
  {
    id: 115,
    label: "Prosecco alla Spina",
    singlePrice: false,
    pricesNum: [5, 11],
    pricesWord: ["undici", "cinque"],
    pricesUnit: ["1 lt", "Calice"],
    pricesIconSize: ["20px"],

    pricesIconRef: ["wineJugSmall", "wineGlassWhite"],
  },
  {
    id: 71,
    label: "Birra alla Spina",
    secondLabel: "Estrella Galicia",
    singlePrice: false,
    pricesNum: [10, 5, 3],
    pricesWord: ["dieci", "cinque", "tre"],
    pricesUnit: ["1 lt", "0.4 cl", "0.25 cl"],
    pricesIconSize: ["25px", "21px", "18px"],
    pricesIconRef: ["beerGlass", "beerGlass", "beerGlass"],
  },
  {
    id: 70,
    label: "Birra",
    secondLabel: 'A rotazione secondo la stagionalità',
    singlePrice: false,
    pricesNum: [11, 4],
    pricesWord: ["undici", "quattro"],
    pricesIconSize: ["30px", "20px"],
    pricesIconRef: ["beerGlass", "beerGlass",],
    pricesUnit: ["1 lt", "0.33 cl"],
  },
  {
    id: 72,
    label: "Coca Cola alla Spina",
    singlePrice: false,
    pricesNum: [10, 5, 3],
    pricesWord: ["dieci", "cinque", "tre"],
    pricesUnit: ["1 lt", "0.5 cl", "0.3 cl"],
    pricesIconSize: ["25px", "21px", "18px"],
    pricesIconRef: ["beerGlass", "beerGlass", "beerGlass"],
  },
  {
    id: 73,
    label: "Coca Cola",
    singlePrice: false,
    pricesNum: [3, 9],
    pricesWord: ["tre e cinquanta"],
    pricesUnit: ["0.33 lt"],
    pricesIconSize: ["30px"],
    pricesIconRef: ["pepsiBottle"],
  },
  {
    id: 74,
    label: "Coca Cola Zero",
    singlePrice: false,
    pricesNum: [3, 9],
    pricesWord: ["tre e cinquanta"],
    pricesUnit: ["0.33 lt"],
    pricesIconSize: ["30px"],
    pricesIconRef: ["pepsiBottle"],
  },
  {
    id: 75,
    label: "Fanta",
    singlePrice: false,
    pricesNum: [3, 9],
    pricesWord: ["tre e cinquanta"],
    pricesUnit: ["0.33 lt"],
    pricesIconSize: ["30px"],
    pricesIconRef: ["pepsiBottle"],
  },
  {
    id: 76,
    label: "Sprite",
    singlePrice: false,
    pricesNum: [3, 9],
    pricesWord: ["tre e cinquanta"],
    pricesUnit: ["0.33 lt"],
    pricesIconSize: ["30px"],
    pricesIconRef: ["pepsiBottle"],
  },
  {
    id: 115,
    label: "Amari",
    singlePrice: false,
    pricesNum: [3.50],
    pricesWord: ["tre e cinquanta"],

    pricesIconSize: ["30px"],

  },
  {
    id: 115,
    label: "Caffè",
    singlePrice: false,
    pricesNum: [1.50],
    pricesWord: ["uno e cinquanta"],

    pricesIconSize: ["30px"],

  },
  {
    id: 116,
    label: "Nutella Caffè",
    singlePrice: false,
    pricesNum: [2],
    pricesWord: ["due"],

    pricesIconSize: ["30px"],

  },
];

export const BIRRE_ARTIGIANLI: IFoodProps[] = [
  {
    id: 77,
    label: "Estrella Galicia 0.0",
    singlePrice: false,
    pricesNum: [5],
    pricesWord: ["cinque"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 78,
    label: "Estrella Galicia Sin Gluten",
    singlePrice: false,
    pricesNum: [5],
    pricesWord: ["cinque"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 79,
    label: "Blonde Ale",
    secondLabel: "Birrificio IBeer",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["sei"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 80,
    label: "Hemp Blanche - Canapa",
    secondLabel: "Birrificio IBeer",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["sei"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 117,
    label: "American IPA",
    secondLabel: "Birrificio IBeer",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["sei"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 118,
    label: "Red Ale",
    secondLabel: "Birrificio IBeer",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["sei"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 119,
    label: "Weiss",
    secondLabel: "Birrificio IBeer",
    singlePrice: false,
    pricesNum: [8],
    pricesWord: ["otto"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
];

export const GIN_TONIC: IFoodProps[] = [
  {
    id: 81,
    label: "Gin Tonic",
    singlePrice: false,
    pricesNum: [8],
    pricesWord: ["otto"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },
  {
    id: 82,
    label: "Spritz",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["sei"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },
  {
    id: 83,
    label: "Spritz Campari",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["sei"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },
  {
    id: 84,
    label: "Americano",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["sei"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },

  {
    id: 86,
    label: "Negroni Sbagliato",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["sei"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },
 
];

export const INGREDIENTS: IIngredientsProps[] = [
  {
    id: 1,
    label: "Pelati San Marzano",
    icon: "icon-park-twotone:tomato",
  },
  {
    id: 2,
    label: "Mozzarella Fiordilatte Caseificio Il Faro",
    icon: "icon-park-twotone:tomato",
  },
  {
    id: 3,
    label: "Basilico Fresco",
    icon: "",
  },
  {
    id: 4,
    label: "Olio Evo",
    icon: "",
  },
  {
    id: 5,
    label: "Pizzella Fritta ripassata al Forno",
    icon: "",
  },
  {
    id: 6,
    label: "Ciliegine di Bufala Caseificio Il Faro",
    icon: "",
  },
  {
    id: 7,
    label: "Coulis di Basilico Fresco",
    icon: "",
  },
  {
    id: 8,
    label: "Passata di Datterino Giallo",
    icon: "",
  },
  {
    id: 9,
    label: "Alici dell'Adriatico",
    icon: "",
  },
  {
    id: 10,
    label: "Olive Taggiasche",
    icon: "",
  },
  {
    id: 11,
    label: "Origano Siciliano",
    icon: "",
  },
  {
    id: 12,
    label: "Crema di Carciofi",
    icon: "",
  },
  {
    id: 13,
    label: "Prosciutto Crudo stagionato d'Assisi",
    icon: "",
  },
  {
    id: 14,
    label: "Funghi Chiodini",
    icon: "",
  },
  {
    id: 15,
    label: "Patate in Crosta cotte al Forno",
    icon: "",
  },
  {
    id: 16,
    label: "Wüstel con Salsa BBQ",
    icon: "",
  },
  {
    id: 17,
    label: "Crema di Aglio Nero Fermentato",
    icon: "",
  },
  {
    id: 18,
    label: "Salsa Worchestershire",
    icon: "",
  },
  {
    id: 19,
    label: "Tabasco",
    icon: "",
  },
  {
    id: 20,
    label: "Peperone Crusco",
    icon: "",
  },
  {
    id: 21,
    label: "Ciauscolo di Muccia IGP",
    icon: "",
  },
  {
    id: 22,
    label: "Erbe di campo ripassate",
    icon: "",
  },
  {
    id: 23,
    label: "Crumble di Taralli",
    icon: "",
  },
  {
    id: 24,
    label: "Ragù di Salsiccia",
    icon: "",
  },
  {
    id: 25,
    label: "Melanzana Fritta",
    icon: "",
  },
  {
    id: 26,
    label: "Ricotta Salata",
    icon: "",
  },
  {
    id: 27,
    label: "Tonno in Porchetta",
    icon: "",
  },
  {
    id: 28,
    label: "Cipolla Rossa di Tropea",
    icon: "",
  },
  {
    id: 29,
    label: "Finocchietto Selvatico",
    icon: "",
  },
  {
    id: 30,
    label: "Pepe in Grani",
    icon: "",
  },
  {
    id: 31,
    label: "Hummus di Edamame",
    icon: "",
  },
  {
    id: 32,
    label: "Stracciatella di bufala della Casa",
    icon: "",
  },
  {
    id: 33,
    label: "Calamaro Scottato alla Griglia",
    icon: "",
  },
  {
    id: 34,
    label: "Mousse di Rapa Rossa",
    icon: "",
  },
  {
    id: 35,
    label: "Bottarga di Muggine",
    icon: "",
  },
  {
    id: 36,
    label: "Crema di Patate al Tartufo Nero Estivo",
    icon: "",
  },
  {
    id: 37,
    label: "Mortadella di Bologna IGP",
    icon: "",
  },
  {
    id: 38,
    label: "Spuma di Pecorino Romano DOP",
    icon: "",
  },
  {
    id: 39,
    label: "Vellutata di Patata Viola",
    icon: "",
  },
  {
    id: 40,
    label: "Tartare di Gambero Imperiale dell'Adriatico",
    icon: "",
  },
  {
    id: 41,
    label: "Chips di Guanciale",
    icon: "",
  },
  {
    id: 42,
    label: 'Tartufo Nero Estivo "Trivelli"',
    icon: "",
  },
  {
    id: 43,
    label: "Stracchino",
    icon: "",
  },
  {
    id: 44,
    label: "Zola",
    icon: "",
  },
  {
    id: 45,
    label: "Cialda di Parmigiano",
    icon: "",
  },
  {
    id: 46,
    label: "Crema di Pecorino Romano DOP",
    icon: "",
  },
  {
    id: 47,
    label: "Pepe",
    icon: "",
  },
  {
    id: 48,
    label: "Cannella",
    icon: "",
  },
  {
    id: 49,
    label: "Patata Schiacciata",
    icon: "",
  },
  {
    id: 50,
    label: "Prosciutto Crudo Croccante",
    icon: "",
  },
  {
    id: 51,
    label: "Olio Evo Aromatizzato al Rosmarino",
    icon: "",
  },
  {
    id: 52,
    label: "Carpaccio di Carne Salada",
    icon: "",
  },
  {
    id: 53,
    label: "Crema di erbe aromatiche",
    icon: "",
  },
  {
    id: 54,
    label: "Zeste di Limone",
    icon: "",
  },
  {
    id: 55,
    label: "Panko",
    icon: "",
  },
  {
    id: 56,
    label: "Mayo al Basilico",
    icon: "",
  },
  {
    id: 57,
    label: "Pepe Rosa",
    icon: "",
  },
  {
    id: 58,
    label: "Lime",
    icon: "",
  },
  {
    id: 59,
    label: "Crumble di Tarallo Pugliese",
    icon: "",
  },
  {
    id: 60,
    label: "Salsa al Tartufo nero Estivo",
    icon: "",
  },
  {
    id: 61,
    label: "Pomodorini secchi",
    icon: "",
  },
  {
    id: 62,
    label: "Glassa al Balsamico",
    icon: "",
  },
  {
    id: 63,
    label: "Noci",
    icon: "",
  },
  {
    id: 64,
    label: "Avocado",
    icon: "",
  },
  {
    id: 65,
    label: "Sesamo",
    icon: "",
  },
  {
    id: 66,
    label: "Fiori Eduli",
    icon: "",
  },
  {
    id: 67,
    label: "Riso Venere",
    icon: "",
  },
  {
    id: 68,
    label: "Mix di Frutta",
    icon: "",
  },
  {
    id: 69,
    label: "Mozzarella di Bufala",
    icon: "",
  },
  {
    id: 70,
    label: "Pane Brusco",
    icon: "",
  },
  {
    id: 71,
    label: "Cipolla Crunchy",
    icon: "",
  },
  {
    id: 72,
    label: "Cremoso all'Avocado",
    icon: "",
  },
  {
    id: 73,
    label: "Gravlax di Salmone ",
    icon: "",
  },
  {
    id: 74,
    label: "Gocce di Ricotta",
    icon: "",
  },
  {
    id: 75,
    label: "Mandorle Tostate",
    icon: "",
  },
  {
    id: 76,
    label: "Tartare di Tonno",
    icon: "",
  },
  {
    id: 77,
    label: "Alga Wakame",
    icon: "",
  },
  {
    id: 78,
    label: "Mayo alla Soia",
    icon: "",
  },
  {
    id: 127,
    label: "Mousse di Rapa Rossa",
    icon: "",
  },
  {
    id: 79,
    label: "Polpo in doppia cottura",
    icon: "",
  },
  {
    id: 80,
    label: "Fonduta di Pecorino Romano DOP",
    icon: "",
  },
  {
    id: 81,
    label: "Tartare di Scottona Marchigiana",
    icon: "",
  },
  {
    id: 82,
    label: "Semi di Sesamo Nero",
    icon: "",
  },
  {
    id: 83,
    label: "Valerianella",
    icon: "",
  },
  {
    id: 84,
    label: "Chips di Cipolla Rossa",
    icon: "",
  },
  {
    id: 85,
    label: "Mayo alla Senape",
    icon: "",
  },
  {
    id: 86,
    label: "Fonduta di Provola Affumicata",
    icon: "",
  },
  {
    id: 87,
    label: "Pulld Pork BBQ",
    icon: "",
  },
  {
    id: 88,
    label: "Crema di Edamame",
    icon: "",
  },
  {
    id: 89,
    label: "Seitan in Tempura",
    icon: "",
  },
  {
    id: 90,
    label: "Patata Viola",
    icon: "",
  },
  {
    id: 91,
    label: "Mousse di Ricotta",
    icon: "",
  },
  {
    id: 92,
    label: "Carpaccio al Cetriolo",
    icon: "",
  },
  {
    id: 93,
    label: "Chips di Mango disidratate",
    icon: "",
  },
  {
    id: 94,
    label: "Tarallo Sbriciolato",
    icon: "",
  },
  {
    id: 95,
    label: "Salsa di Soya",
    icon: "",
  },
  {
    id: 96,
    label: "Guanciale Croccante",
    icon: "",
  },
  {
    id: 97,
    label: "Ricotta al Tartufo",
    icon: "",
  },
  {
    id: 98,
    label: "Granella di Pistacchio",
    icon: "",
  },
  {
    id: 99,
    label: "Ricotta salata Caseificio Il Faro",
    icon: "",
  },
  {
    id: 100,
    label: "Calamari Fritti",
    icon: "",
  },
  {
    id: 101,
    label: "Mayo agli Agrumi",
    icon: "",
  },
  {
    id: 102,
    label: "Pomodoro alla brace della Casa",
    icon: "",
  },
  {
    id: 103,
    label: "Nduja di Spilinga",
    icon: "",
  },
  {
    id: 104,
    label: "Olive nere disidratate",
    icon: "",
  },
  {
    id: 105,
    label: "Salsa verde piccante",
    icon: "",
  },
  {
    id: 106,
    label: "Patata viola schiacciata",
    icon: "",
  },
  {
    id: 107,
    label: "Salmone Selvaggio",
    icon: "",
  },
  {
    id: 108,
    label: "Sesamo nero tostato",
    icon: "",
  },
  {
    id: 109,
    label: "Mirtilli rossi disidratati BIO",
    icon: "",
  },
  {
    id: 110,
    label: "Pomodorini semi dry",
    icon: "",
  },
  {
    id: 111,
    label: "Cremoso alle mandorle",
    icon: "",
  },
  {
    id: 112,
    label: "Basilico in cottura",
    icon: "",
  },
  {
    id: 113,
    label: "Burrata 125 gr.",
    icon: "",
  },
  {
    id: 114,
    label: "Frolla salata al Basilico",
    icon: "",
  },
  {
    id: 115,
    label: "Crema di funghi porcini",
    icon: "",
  },
  {
    id: 116,
    label: "Carciofi Fritti",
    icon: "",
  },
  {
    id: 117,
    label: "Noodles di patate croccanti",
    icon: "",
  },
  {
    id: 118,
    label: "Prosciutto cotto di Praga",
    icon: "",
  },
  {
    id: 119,
    label: "Mayo alla Paprika",
    icon: "",
  },
  {
    id: 120,
    label: "Gorgonzola Dolce",
    icon: "",
  },
  {
    id: 121,
    label: "Cremoso di Ricotta alle Mandorle",
    icon: "",
  },
  {
    id: 122,
    label: "Salsa Tahini",
    icon: "",
  },
  {
    id: 123,
    label: "Zabaione Salato",
    icon: "",
  },
  {
    id: 124,
    label: "Porchetta della Casa",
    icon: "",
  },
  {
    id: 125,
    label: 'Carbocrema',
    icon: ''
  },
  {
    id: 126,
    label: 'Pecorino Romano DOP',
    icon: ''
  },
  {
    id: 129,
    label: "Crudo di Parma 18 mesi",
    icon: ''
  },
  {
    id: 130,
    label: 'Granella di Noci',
    icon: '',
  },
  {
    id: 131,
    label: 'Gelato ai fichi della casa',
    icon: ''
  },
  {
    id: 132, 
    label: 'Crema di Zucca',
    icon: '',
  },
  {
    id: 133,
    label: 'Provola affumicata',
    icon: '',
  },
  {
    id: 134,
    label: 'Salsiccia',
    icon: '',
  },

];

export const searchWord: ISearchWord[] = [
  {
    value: "1",
    label: "Cozze",
  },
  {
    value: "2",
    label: "Pesce",
  },
  {
    value: "3",
    label: "Alici",
  },
  {
    value: "4",
    label: "Calamari",
  },
  {
    value: "5",
    label: "Frutti di Mare",
  },
  {
    value: "6",
    label: "Salumi e Formaggi",
  },
  {
    value: "7",
    label: "Carne",
  },
  {
    value: "8",
    label: "Carpaccio",
  },
  {
    value: "9",
    label: "Polpo",
  },
  {
    value: "10",
    label: "Salmone",
  },
  {
    value: "11",
    label: "Tonno",
  },
  {
    value: "12",
    label: "Gambero",
  },
  {
    value: "13",
    label: "Mozzarella",
  },
  {
    value: "14",
    label: "Pomodoro",
  },
  {
    value: "15",
    label: "Mozzarella Fiordilatte",
  },
  {
    value: "16",
    label: "Mozzarella di Bufala",
  },
  {
    value: "17",
    label: "Olive",
  },
  {
    value: "18",
    label: "Carciofi",
  },
  {
    value: "19",
    label: "Prosciutto Crudo",
  },
  {
    value: "20",
    label: "Funghi",
  },
  {
    value: "21",
    label: "Patate",
  },
  {
    value: "22",
    label: "Wüstel",
  },
  {
    value: "23",
    label: "Peperoni",
  },
  {
    value: "24",
    label: "Ciauscolo",
  },
  {
    value: "25",
    label: "Salsiccia",
  },
  {
    value: "26",
    label: "Melanzane",
  },
  {
    value: "27",
    label: "Ricotta",
  },
  {
    value: "28",
    label: "Bottarga",
  },
  {
    value: "29",
    label: "Mortadella",
  },
  {
    value: "30",
    label: "Tartufo",
  },
  {
    value: "31",
    label: "Stracchino",
  },
  {
    value: "32",
    label: "Zola",
  },
  {
    value: "33",
    label: "Avocado",
  },
  {
    value: "34",
    label: "Guanciale",
  },
  {
    value: "35",
    label: "Pecorino",
  },
  {
    value: "36",
    label: "Pulled Pork",
  },
  {
    value: "37",
    label: "Edamame",
  },
  {
    value: "38",
    label: "Pollo",
  },
  {
    value: "39",
    label: "Burrata",
  },
  {
    value: "40",
    label: "Parmigiano",
  },
  {
    value: "41",
    label: "Nduja",
  },
  {
    value: "42",
    label: "Mirtilli",
  },
  {
    value: "43",
    label: "Verdura",
  },
  {
    value: "44",
    label: "Prosciutto cotto",
  },
  {
    value: "45",
    label: "Paprika",
  },
];

export const FOODS = [
  ...ENTREE,
  ...TARTARE,
  ...PIZZE_LE_TRADIZIONALI,
  ...PIZZE_LE_CONTEMPORANEE,
  ...PIZZE_SENZA_GLUTINE,
  ...SEI_SPICCHI,
  ...FRITTI,
];

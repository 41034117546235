import { styled, Dialog } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: "blur(5px)",
  "& .MuiPaper-root": {
    width: "95%",
    minWidth: "90%",
    padding: "0px",
    borderRadius: "20px",
    backgroundColor:
      theme.palette.mode === "light" ? theme.palette.secondary.main : theme.palette.secondary.dark,
  },
}));

import { useTheme } from "@mui/material";
import { SideMenu, Header, SearchDrawer, AppBar } from "components";
import { NotInLocalLayout } from "layouts/NotInLocalLayout/NotInLocalLayout";
import { Outlet } from "react-router-dom";

const PublicLayout = ({
  withoutHeader = false,
}: {
  withoutHeader?: boolean;
}) => {
  //hooks
  const theme = useTheme();

  return (
    <NotInLocalLayout>
      {!withoutHeader ? <Header /> : null}
      <SideMenu />
      <SearchDrawer />
      <div
        style={{
          minHeight: "100vh",
          width: "100%",
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[50],
        }}
      >
        <Outlet />
      </div>
      <AppBar />
    </NotInLocalLayout>
  );
};

export { PublicLayout };

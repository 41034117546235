import { Stack, Typography, useTheme } from "@mui/material";
import { Logo } from "assets";
import { Page } from "components";
import gsap, { Power1 } from "gsap";
import useTranslation from "i18n";
import { NotInLocalLayout } from "layouts";
import { useEffect, useRef } from "react";
const Home = () => {
  //hooks
  const { palette } = useTheme();
  const { i18next } = useTranslation();
  //ref

  const LogoRef = useRef(null);
  const textRef = useRef(null);
  const presentationRef = useRef(null);
  //useEffect
  useEffect(() => {
    gsap.fromTo(
      LogoRef.current,
      {
        scale: 0,
        duration: 0.2,
        opacity: 0,
        delay: 0.5,
      },
      {
        scale: 1,
        duration: 0.2,
        opacity: 1,
        delay: 0.5,
        ease: Power1.easeIn,
      }
    );
    gsap.fromTo(
      presentationRef.current,
      {
        y: 10,
        duration: 0.5,
        opacity: 0,
        delay: 1,
      },
      {
        y: 0,
        duration: 0.5,
        opacity: 1,
        delay: 1,
        ease: Power1.easeIn,
      }
    );
    gsap.fromTo(
      textRef.current,
      {
        y: 10,
        duration: 0.5,
        opacity: 0,
        delay: 1.5,
      },
      {
        y: 0,
        duration: 0.5,
        opacity: 1,
        delay: 1.5,
        ease: Power1.easeIn,
      }
    );
  }, []);
  return (
    <Page title="Menù">
      <NotInLocalLayout>
        <Stack
          sx={{
            height: "100vh",
            width: "100%",
            position: "fixed",
            paddingBottom: "150px",
            backgroundColor:
              palette.mode === "dark" ? palette.grey[800] : palette.grey[50],
          }}
          gap="40px"
          justifyContent="center"
          alignItems="center"
        >
          <Logo width="100px" logoRef={LogoRef} />
          <Typography
            variant="body1"
            ref={presentationRef}
            sx={{ width: "70%", fontWeight: 100, textAlign: "center" }}
          >
            {i18next.t("presentation")}
          </Typography>
          <Typography variant="h6" ref={textRef} sx={{ fontWeight: "300" }}>
            MENÙ
          </Typography>
        </Stack>
      </NotInLocalLayout>
    </Page>
  );
};

export default Home;

import {
  AdvancedFilterContainer,
  StyledAccordion,
  StyledDrawer,
} from "./SearchDrawer.style";
import { useHeader } from "hooks";
import {
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { AppBarMenEn, AppBarMenu, IAppBarMenuProps } from "constants/settings";
import {
  FOODS,
  ENTREE,
  TARTARE,
  PIZZE_LE_CONTEMPORANEE,
  PIZZE_LE_TRADIZIONALI,
  SEI_SPICCHI,

  IFoodProps,
  ISearchWord,
  PIZZE_SENZA_GLUTINE,
} from "constants/food";
import { Stack } from "@mui/material";
import useTranslation from "i18n";
import { useGlobalStore } from "stores/global";
import {
  ENTREE_EN,
  PIZZE_LE_CONTEMPORANEE_EN,
  PIZZE_LE_TRADIZIONALI_EN,
  PIZZE_SENZA_GLUTINE_EN,
  SEI_SPICCHI_EN,
  TARTARE_EN,
} from "constants/foodEn";
import { Autocomplete } from "../Form";
import { Card } from "../Card/Card";

interface ICategoriesProps {
  [key: string]: boolean;
}

const SearchDrawer = () => {
  //hooks
  const { searchDrawer } = useHeader();
  const { palette } = useTheme();
  const { i18next } = useTranslation();
  const { activeLocale } = useGlobalStore();
  //state
  const [expand, setExpand] = useState<boolean>(false);
  const [option, setOption] = useState<ISearchWord[]>([]);
  const [dishes, setDishes] = useState<IFoodProps[]>([]);
  const [categories, setCategories] = useState<ICategoriesProps>({
    entreetartare: true,
    pizze: true,
    seispicchi: true,
    fritti: true,
  });

  const [allFoods, setAllFoods] = useState<IFoodProps[]>(FOODS);

  useEffect(() => {
    const ref: { [key: string]: IFoodProps[] } = {
      entreetartare:
        activeLocale === "it"
          ? [...ENTREE, ...TARTARE]
          : [...ENTREE_EN, ...TARTARE_EN],
      pizze:
        activeLocale === "it"
          ? [...PIZZE_LE_CONTEMPORANEE, ...PIZZE_LE_TRADIZIONALI]
          : [...PIZZE_LE_CONTEMPORANEE_EN, ...PIZZE_LE_TRADIZIONALI_EN],
      seispicchi: activeLocale === "it" ? SEI_SPICCHI : SEI_SPICCHI_EN,
      fritti: activeLocale === "it"
      ? PIZZE_SENZA_GLUTINE : PIZZE_SENZA_GLUTINE_EN,
    };

    const truthyCategories = Object.entries(categories)
      .filter((el) => el[1] === true)
      .map((el) => el[0]);

    const allFoods = truthyCategories.map((el) => ref[el]).flat();
    setAllFoods(allFoods);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    if (option.length > 0 && allFoods.length > 0) {
      const options = option.map((el) => +el.value);
      const food = allFoods.filter((food) =>
        options.every((o) => food.searchWord?.includes(o))
      );
      setDishes(food);
    } else {
      setDishes([]);
    }
  }, [option, option.length, allFoods]);

  useEffect(() => {
    if (!searchDrawer) {
      setDishes([]);
      setOption([]);
    }
  }, [searchDrawer]);
  return (
    <StyledDrawer open={searchDrawer} anchor="right">
      <StyledAccordion elevation={1} expanded={expand && searchDrawer}>
        <AccordionSummary
          expandIcon={
            <IconButton size="small" onClick={() => setExpand(!expand)}>
              <Icon
                icon="material-symbols:filter-list"
                style={{ fontSize: "25px" }}
              />
            </IconButton>
          }
        >
          <Autocomplete
            onChange={(value: ISearchWord[]) => {
              setOption(value);
            }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <AdvancedFilterContainer>
            <Typography variant="caption">{i18next.t("escludi")}</Typography>
            {(activeLocale === "it" ? AppBarMenu : AppBarMenEn)
              .slice(0, AppBarMenu.length - 2)
              .map((el: IAppBarMenuProps, index: number) => (
                <FormControlLabel
                  key={`switchFilter${index}`}
                  label={el.label}
                  control={
                    <Switch
                      defaultChecked={true}
                      color={palette.mode === "light" ? "primary" : "info"}
                      name={el.value}
                      onChange={(_, value) =>
                        setCategories({ ...categories, [el.value]: value })
                      }
                    />
                  }
                />
              ))}
          </AdvancedFilterContainer>
        </AccordionDetails>
      </StyledAccordion>
      <Stack sx={{ width: "100%", marginTop: "10px", padding: "10px" }}>
        {dishes.length > 0 ? (
          <Typography variant="body1">
            {i18next.t("abbiamotrovato")} {dishes.length}{" "}
            {dishes.length > 1 ? i18next.t("proposte") : i18next.t("proposta")}
          </Typography>
        ) : null}
        {dishes.length === 0 && option.length === 0 ? (
          <Typography variant="body1">{i18next.t("no-ingredients")}</Typography>
        ) : null}
        {dishes.length === 0 && option.length > 0 ? (
          <Typography variant="body1">{i18next.t("noproposal")}</Typography>
        ) : null}
        {dishes.length > 0 ? (
          <Stack gap="10px" sx={{ marginTop: "10px" }}>
            {dishes.map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                showGlutenFree
                key={`searchDish${index}`}
                showCategory={true}
              />
            ))}
          </Stack>
        ) : null}
      </Stack>
    </StyledDrawer>
  );
};

export { SearchDrawer };

import { useDialog } from "hooks/useDialog";
import { StyledDialog } from "./Dialog.style";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const Dialog = () => {
  //hooks
  const { dialog, closeDialog } = useDialog();
  return (
    <StyledDialog open={dialog.open}>
      <DialogTitle>{dialog?.title}</DialogTitle>
      <DialogContent>{dialog.text}</DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()}>Chiudi</Button>
      </DialogActions>
    </StyledDialog>
  );
};

export { Dialog };

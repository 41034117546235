import { ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet";
import { IPageProps } from "./Page.types";
import { PlaceSettingContainer, PlaceSettingLine } from "./Page.style";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import useTranslation from "i18n";
import { NEWYEAR } from "constants/paths";

const Page = ({ title, children }: IPageProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet>
        <title>{`${title} | Mamà Pizzeria`}</title>
      </Helmet>
      {children}
    </>
  );
};

const PageContainer = ({ children}: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const {i18next} = useTranslation()
  return (
    <div style={{ padding: "100px 20px 100px 20px" }}>
      {children}
      {pathname === "/allergeni" || pathname === "/i_miei_preferiti" || pathname === NEWYEAR ? null : (
        <PlaceSettingContainer>
          <Typography variant="caption" whiteSpace="nowrap" sx={{ lineHeight: 0 }}>
            {i18next.t('coperto')}
          </Typography>
          <PlaceSettingLine />
          <Typography
            variant="body1"
            sx={{ fontFamily: "Mamà Cursive", fontSize: "30px", lineHeight: "6px" }}
          >
            {i18next.t('due')}
          </Typography>
        </PlaceSettingContainer>
      )}
    </div>
  );
};

export { Page, PageContainer };

import { Typography } from "@mui/material";
import { ButtonContainer } from "./MyButton.style";
import { IMyButtonProps } from "./MyButton.types";

const MyButton = ({ children, onClick }: IMyButtonProps) => {
  return (
    <ButtonContainer onClick={onClick}>
      <Typography variant="h5" onClick={onClick}>
        {children}
      </Typography>
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></div>
    </ButtonContainer>
  );
};

export { MyButton };

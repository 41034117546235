import { create } from "zustand";

interface IUseDialogProps {
  dialog: IDialogHookProps;
  openDialog: Function;
  closeDialog: Function;
}

export interface IDialogHookProps {
  open: boolean;
  title: string | null;
  text: string | null;
}

const useDialog = create<IUseDialogProps>((set) => ({
  dialog: { open: false, title: null, text: null },
  openDialog: (title: string, text: string) =>
    set(() => ({ dialog: { title: title, text: text, open: true } })),
  closeDialog: () => set(() => ({ dialog: { title: null, text: null, open: false } })),
}));

export { useDialog };

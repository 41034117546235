import { Stack } from "@mui/material";
import { Card, Page, PageContainer, TitleSection } from "components";
import { IFoodProps, PIZZE_SENZA_GLUTINE } from "constants/food";
import { PIZZE_SENZA_GLUTINE_EN } from "constants/foodEn";
import { useFavorites } from "hooks/useFavorites";
import useTranslation from "i18n";
import { useGlobalStore } from "stores/global";
import { addToLocalStorage } from "utils/localStorage";
const Fritti = () => {
  //hooks
  const { favorites, addFavorites, removeFavorites, showSnackbar } =
    useFavorites();
  const { activeLocale } = useGlobalStore();
  const { i18next } = useTranslation();
  //function
  const handleFavorite = (id: number, name: string) => {
    const isPresent = favorites.find((el) => el === id);
    if (Boolean(isPresent)) {
      showSnackbar(`${name} rimosso dai preferiti`);
      removeFavorites(id);
      addToLocalStorage(id);
      return;
    }
    if (!Boolean(isPresent)) {
      showSnackbar(`${name} aggiunto ai preferiti`);
      addFavorites(id);
      addToLocalStorage(id);
      return;
    }
  };

  const isPresentInFavorites = (id: number) => {
    const isPresent = favorites.find((el) => el === id);
    return Boolean(isPresent);
  };
  return (
    <Page title={i18next.t('head-senza-glutine')}>
      <PageContainer>
        <Stack sx={{ marginTop: "20px" }}>
          <TitleSection title={i18next.t("senza-glutine")} />
          <Stack gap="15px">
            {(activeLocale === "it"
              ? PIZZE_SENZA_GLUTINE
              : PIZZE_SENZA_GLUTINE_EN
            ).map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                key={`fries${index}`}
                clickFavorites={handleFavorite}
                isFavorite={isPresentInFavorites(el.id)}
              />
            ))}
          </Stack>
        </Stack>
      </PageContainer>
    </Page>
  );
};

export default Fritti;

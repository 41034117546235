export const EYE = 'solar:eye-bold'
export const EYE_CLOSE = 'solar:eye-closed-bold'
export const MOON = 'solar:moon-bold-duotone'
export const SUN = 'solar:sun-2-bold-duotone'
export const BELL = 'solar:bell-bold-duotone'



export const EMAIL = 'iconamoon:email-thin'
export const LOCK = 'solar:lock-broken'

export const BURGER = 'solar:hamburger-menu-broken'
export const CLOSE = 'solar:close-circle-broken'
export const DASHBOARD = 'solar:pie-chart-2-bold-duotone'
export const LOGOUT = 'solar:logout-2-broken'


export const CLIENTS  = "solar:users-group-two-rounded-bold-duotone"

export const CALENDAR = 'solar:calendar-bold-duotone'
export const PLUS = 'solar:add-square-bold'
export const PROGRESS = 'material-symbols:progress-activity'
export const WORK = 'solar:suitcase-bold-duotone'
export const CLIENT_SECOND = 'solar:users-group-rounded-bold'
export const PROJECTS = 'solar:documents-bold'
export const BILLS = 'solar:bill-list-bold-duotone'
export const CHECK = 'tdesign:check'
export const MONEY = 'solar:wallet-money-bold-duotone'
export const INFO = 'solar:info-square-bold'
export const DELETE = 'solar:trash-bin-minimalistic-2-bold'
export const EDIT = 'lets-icons:edit-duotone'
export const QUOTATIONS = 'solar:clipboard-bold'
export const DOWNLOAD = 'solar:download-bold'
export const FILTER = 'solar:filter-bold-duotone'
export const BRICK = 'solar:menu-dots-square-bold-duotone'
export const PROJECT = 'solar:suitcase-lines-bold-duotone'
export const SETTINGS = 'solar:settings-bold'
export const CODE = 'solar:code-bold'
export const REQUESTS = 'solar:user-hand-up-bold-duotone'
export const SEARCH = 'tabler:search'
export const ARROW_LEFT = "solar:alt-arrow-left-broken"
export const ARROW_RIGHT = "solar:alt-arrow-right-broken"
export const SHILD = 'solar:shield-user-bold-duotone'
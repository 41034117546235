import { IAutocompleteProps } from "./Autocomplete.types";
import {
  Autocomplete as MuiAutocomplete,
  Chip,
  TextField,
  useTheme,
} from "@mui/material";

import { searchWord, ISearchWord } from "constants/food";
import { searchWordEn } from "constants/foodEn";
import useTranslation from "i18n";
import { useGlobalSelector } from "stores/global";
const Autocomplete = ({ onChange }: IAutocompleteProps) => {
  const { palette } = useTheme();
  const { i18next } = useTranslation();
  const { activeLocale } = useGlobalSelector();
  return (
    <MuiAutocomplete
      multiple
      fullWidth
      noOptionsText={i18next.t("nooptions")}
      clearText={i18next.t("pulisci")}
      closeText={i18next.t("chiudi")}
      limitTags={2}
      onChange={(_, value) => onChange(value)}
      openText={i18next.t("apri")}
      sx={{ marginRight: "10px" }}
      renderInput={(params) => (
        <TextField color="primary" {...params} label={i18next.t("voglia")} />
      )}
      options={
        (activeLocale === "it" ? searchWord : searchWordEn).sort((a, b) =>
          a.label > b.label ? 1 : -1
        ) as ISearchWord[]
      }
      renderTags={(value: { label: string; value: string }[], getTagProps) =>
        value.map((option: { label: string; value: string }, index: number) => (
          <Chip
            variant="outlined"
            color={"primary"}
            label={option.label}
            sx={
              palette.mode === "light"
                ? {
                    borderColor: palette.grey[700],
                    color: palette.grey[700],
                    "& .MuiSvgIcon-root": { color: palette.grey[700] },
                  }
                : {}
            }
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

export { Autocomplete };

import { keyframes, styled } from "@mui/material";

export const ScrollTopButtonContainer = styled("div")<{ show: boolean }>(
  ({ show }) => ({
    position: "fixed",
    bottom: show ? 80 : "-50px",
    right: 15,
    transition: "all 0.5s ease",
  })
);

export const ButtonContainer = styled("div")({
  height: "70px",
  width: "50px",
  position: "relative",
});

const animation = keyframes`
    0% {
        top: 20px
    };
    50% {
        top: 0px
    }
    100% {
        top: 20px
    }
`;

export const Button = styled("div")(({ theme }) => ({
  height: "50px",
  width: "50px",
  position: "relative",
  top: "20px",
  borderRadius: "10px",
  overflow: "hidden",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[100],
  boxShadow: `0px 5px 10px #00000030, inset 0px 0px 50px ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[50]
  }`,
  borderLeft: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : theme.palette.grey[50]
  }`,
  borderRight: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : theme.palette.grey[50]
  }`,
  borderTop: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : theme.palette.grey[50]
  }`,
  borderBottom: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : theme.palette.grey[50]
  }`,
  animation: `${animation} infinite 1s cubic-bezier(.57,.54,.43,.87)`,
}));

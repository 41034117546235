import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { useGlobalStore } from "stores/global";

const useTranslation = () => {
  const { activeLocale } = useGlobalStore();

  const translations: any = {
    it: {
      translation: {
        entree: "Entreé",
        servedMontanarina: "Servite con Montanarina fritta",
        coperto: "Coperto",
        due: "due",
        contemporanee: "Le Contemporanee",
        tradizionali: "Le Tradizionali",
        "senza-glutine": "Le Senza Glutine",
        "head-senza-glutine": "Pizze Senza Glutine",
        "gluternChip": "Senza Glutine",
        "head-pizza": "Pizze",
        "head-sei-spicchi": "Sei Spicchi",
        "head-entree": "Entrèe & Tartare",
        "sei-spicchi": "Le Sei Spicchi",
        "sei-spicchi-text":
          "         Un impasto più alto, esternamente croccante e con massima idratazione. <br /> Le nostre Sei Spicchi sono caratterizzate da una farina costituita da più di 2mila grani provenienti da tutto il mondo.",
        dolci: "Dolci",
        "bevande-page": "Bevande",
        bollicine: "BOLLICINE",
        champagne: "CHAMPAGNE",
        "vini-bianchi": "VINI BIANCHI",
        "vini-rossi": "VINI ROSSI",
        bevande: "BEVANDE",
        birre: "BIRRE ARTIGIANALI",
        cocktails: "COCKTAILS",
        "bollicine-title": "Bollicine",
        "champagne-title": "Champagne",
        "vini-bianchi-title": "Vini Bianchi",
        "vini-rossi-title": "Vini Rossi",
        "bevande-title": "Bevande",
        "birre-title": "Birre Artigianali",
        "cocktails-title": "Cocktails",
        preferiti: "I MIEI PREFERITI",
        "allergeni-side": "LISTA ALLERGENI",
        lingua: "Lingua",
        voglia: "Ho voglia di...",
        escludi: "Escludi Categorie:",
        "no-ingredients": "Nessun ingrediente selezionato",
        abbiamotrovato: "Abbiamo trovato",
        proposta: "proposta:",
        proposte: "proposte:",
        noproposal: "    Non abbiamo proposte con gli ingredienti selezionati",
        nooptions: "Non ci sono opzioni",
        chiudi: "Chiudi",
        pulisci: "Pulisci",
        apri: "Apri",
        presentation:
          "  Mamà rappresenta un nuovo concetto di pizzeria, un percorso che nasce dalla ricerca e dal rispetto delle materie prime creando basi solide per offrirvi sempre un prodotto contemporaneo e in continua evoluzione.",
        allergeni: 'Allergeni',
        "a-1": "Cereali contenenti glutine, cioè grano, segale, orzo, avena, farro, kamut, o i loro ceppi derivati",
        "a-2": "Crostacei e prodotti a base di crostacei",
        "a-3": "Uova e prodotti a base di uova",
        "a-4": "Pesce e prodotti a base di pesce",
        "a-5": "Arachidi e prodotti a base di arachidi",
        "a-6": "Soia e prodotti a base di soia",
        "a-7": "Latte e prodotti a base di latte (incluso lattosio)",
        "a-8": "Frutta a guscio, cioè mandorle, nocciole, noci, noci agagiù, noci del Brasile, pistacchi, noci di macadamia, noci del Queensland e i loro prodotti derivati",
        "a-9": "Sedano e podotti a base di sedano",
        "a-10": "Senape e prodotti a base di senape",
        "a-11": "Semi di sesamo e prodotti a base di semi di sesamo",
        "a-12":"Anidride solforosa e solfiti in concentrazioni superiori a 10 mg/Kg",
        "a-13": "Lupini e prodotti a base di lupini",
        "a-14": "Molluschi e prodotti a base di molluschi",
        "a-list": "Elenco delle sostanze o prodotti che provocano allergie o intolleranze utitlizzati in questo esercizio e presenti nell'allegato II del Reg. UE n. 1169/2011",
        "a-info": "Per ulteriori informazioni rivolgersi al personale di sala.",
        metodo: "Metodo",
        "favoriti": "I Miei Preferiti",
        "notInLocal": 'Ti Aspettiamo al locale per farti provare i nostri piatti e le nostre pizze!'
      },
    },
    en: {
      translation: {
        entree: "Starters",
        servedMontanarina: "Served with Fried Montanarina",
        coperto: "Table Charge",
        due: "two",
        contemporanee: "Le Contemporanee",
        tradizionali: "Le Tradizionali",
        "senza-glutine": "Gluten Free",
        "head-senza-glutine": "Gluten-Free Pizzas",
        "gluternChip": 'Gluten-Free',
        "head-pizza": "Pizzas",
        "head-entree": "Starter & Tartare",

        "head-sei-spicchi": "Sei Spicchi",
        "sei-spicchi": "Le Sei Spicchi",
        "sei-spicchi-text":
          "A higher dough, externally crispy and with maximum hydration. <br/> Our Six Slices are characterized by a flour made up of more than 2,000 grains from all over the world.",
        dolci: "Dessert",
        "bevande-page": "Drinks",
        bollicine: "SPARKLING WINES",
        champagne: "CHAMPAGNE",
        "vini-bianchi": "WHITE WINES",
        "vini-rossi": "RED WINES",
        bevande: "DRINKS",
        birre: "CRAFT BEERS",
        cocktails: "COCKTAILS",
        "bollicine-title": "Sparkling Wines",
        "champagne-title": "Champagne",
        "vini-bianchi-title": "White Wines",
        "vini-rossi-title": "Red Wines",
        "bevande-title": "Drinks",
        "birre-title": "Craft Beers",
        "cocktails-title": "Cocktails",
        preferiti: "MY FAVORITES",
        "allergeni-side": "ALLERGENS LIST",
        lingua: "Language",
        voglia: "I want to...",
        escludi: "Exclude Categories:",
        "no-ingredients": "No ingredients selected",
        abbiamotrovato: "We found",
        proposta: "proposal:",
        proposte: "proposals:",
        noproposal:
          "We don't have any suggestions with the selected ingredients.",
        nooptions: "There are no options.",
        chiudi: "Close",
        pulisci: "Clean",
        apri: "Open",
        presentation:
          "Mamà represents a new concept of pizzeria, a journey born from the research and respect for raw materials, creating solid foundations to always offer you a contemporary and constantly evolving product.",
        allergeni: 'Allergens',
        "a-1": "Cereals containing gluten, namely wheat, rye, barley, oats, spelt, kamut, or their hybrid strains",
        "a-2": "Crustaceans and crustacean products",
        "a-3": "Eggs and egg products",
        "a-4": "Fish and fish products",
        "a-5": "Peanuts and peanut products",
        "a-6": "Soybeans and soybean products",
        "a-7": "Milk and milk products (including lactose)",
        "a-8": "Nuts, namely almonds, hazelnuts, walnuts, cashews, pecans, Brazil nuts, pistachios, macadamia nuts, Queensland nuts, and their derivatives",
        "a-9": "Celery and celery products",
        "a-10": "Mustard and mustard products",
        "a-11": "Sesame seeds and sesame seed products",
        "a-12":"Sulphur dioxide and sulphites in concentrations greater than 10 mg/kg",
        "a-13": "Lupins and lupin products",
        "a-14": "Molluscs and mollusc products",
        "a-list": "List of substances or products causing allergies or intolerances used in this exercise and listed in Annex II of EU Regulation No. 1169/2011.",
        "a-info": "For further information, please contact the waitstaff.",
        metodo: 'Method',
        "favoriti": "My Favorites",
        "notInLocal": 'We look forward to welcoming you to the restaurant to let you try our dishes and pizzas!'


        },
    },
  };

  i18next.use(initReactI18next).init({
    resources: translations,
    lng: activeLocale,
    interpolation: {
      escapeValue: false,
    },
  });
  return { i18next };
};

export default useTranslation;

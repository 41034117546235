import { makePath } from "utils/navigation";




export const ROOT = "/";

//PUBLIC
export const PUBLIC = makePath(ROOT, "public");
export const HOME = makePath(PUBLIC, "/home");
export const ENTREE_E_TARTARE = makePath(PUBLIC, "/entree_&_tartare");
export const SEI_SPICCHI = makePath(PUBLIC, "/sei_spicchi");
export const PIZZE = makePath(PUBLIC, "/pizze");
export const DOLCI = makePath(PUBLIC, "/dolci");
export const FRITTI = makePath(PUBLIC, "/fritti");
export const BEVANDE = makePath(PUBLIC, "/bevande");
export const FAVORITE = makePath(PUBLIC, "/i_miei_preferiti");
export const ALLERGENES = makePath(PUBLIC, "/allergeni");
export const NEWYEAR = makePath(PUBLIC, "/new_year")


//AUTH
export const AUTH = makePath(ROOT, "auth");
export const AUTH_LOGIN = makePath(AUTH, '/login')



//ADMIN
export const ADMIN = "/admin";

import { styled, Snackbar } from "@mui/material";

export const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  "&.MuiSnackbar-anchorOriginBottomCenter": {
    bottom: "80px",
    zIndex: 1,
    "& .MuiPaper-root": {
      borderRadius: "10px",
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.main,
      color: theme.palette.mode === "dark" ? theme.palette.grey[50] : theme.palette.grey[900],
      fontWeight: "300",
      fontSize: "16px",
      borderLeft: `2px solid ${theme.palette.secondary.dark}`,
      borderRight: `2px solid ${theme.palette.secondary.light}`,
      borderTop: `2px solid ${theme.palette.secondary.light}`,
      borderBottom: `2px solid ${theme.palette.secondary.dark}`,
    },
  },
}));

import { styled, Theme } from "@mui/material";

export const AppBarContainer = styled("div")(
  ({ theme, withShadow }: { theme?: Theme; withShadow: Boolean }) => ({
    height: "70px",
    width: "100%",
    position: "fixed",
    bottom: 0,
    backgroundColor: theme?.palette.mode === 'dark' ? theme.palette.grey[800] : theme?.palette.grey[50],
    boxShadow: withShadow ? "0px -0px 10px rgba(0, 0, 0, 0.3)" : "none",
    display: "flex",
    overflow: "hidden",
    zIndex: 999,
  })
);

export const LineContainer = styled("div")(() => ({
  height: "3px",
  width: "100%",
  position: "absolute",
  top: "0",
  left: 0,
  zIndex: 9,
}));

export const Line = styled("div")(
  ({ theme, position, showLine }: { theme?: Theme; position: number; showLine: boolean }) => ({
    height: "100%",
    width: showLine ? "calc(100% / 6)" : 0,
    position: "relative",
    left: position,
    backgroundColor: theme?.palette.mode === 'dark' ? theme.palette.grey[50] : theme?.palette.grey[700],
    transition: "all .2s cubic-bezier(0.4, 0, 0.2, 1)",
  })
);

export const StyledButton = styled("div")(
  ({ active, theme }: { active: boolean; theme?: Theme }) => ({
    height: "100%",
    width: "100%",
    borderLeft: "1px solid rgba(125, 125, 125, 0.4)",
    position: "relative",
    boxShadow: active
      ? theme?.palette.mode === "light"
        ? "inset 1px 1px 10px rgba(0, 0, 0, 0.3)"
        : "inset 1px 1px 10px rgba(0, 0, 0, 0.9)"
      : "transparent",
  })
);

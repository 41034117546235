import { IFoodProps, IIngredientsProps, ISearchWord } from "./food";

export const ENTREE_EN: IFoodProps[] = [
  {
    id: 3,
    label: "Fried Calamari",
    singlePrice: true,
    priceNum: 10,
    ingredients: [57, 58],
    priceWord: "ten",
    allergenes: [1, 3, 4, 9, 10, 11],
    searchWord: [2, 4],
    category: 1,
    advised: "Structured White Wine or Sparkling White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 1,
    label: "Fried Burrata in Pizza Dough",
    singlePrice: true,
    priceNum: 8,
    priceWord: "eight",
    allergenes: [1, 7],
    searchWord: [39],
    category: 1,
    advised: "Semi-Aromatic White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 5,
    label: "Charcuterie board with local cured meats & cheeses",
    secondLabel: "Served with Fried Montanarina",
    singlePrice: true,
    priceNum: 8,
    priceWord: "ten",
    allergenes: [1, 7, 8, 12],
    searchWord: [6, 7],
    category: 1,
    advised: "Structured Red Wine or Red Beer",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 3,
    label: "French Fries",
    singlePrice: true,
    priceNum: 5,
    priceWord: "five",
    searchWord: [21],
    category: 1,
    advised: "Structured White Wine or Sparkling White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 4,
    label: "Spicy Dippers",
    singlePrice: true,
    priceNum: 5.5,
    priceWord: "five.fifty",
    category: 1,
    advised: "Structured White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 37,
    label: "Vegetables in Tempura",
    singlePrice: true,
    priceNum: 6,
    priceWord: "six",
    allergenes: [1, 3],
    category: 5,
  },
  {
    id: 38,
    label: "Diavoletti with Hot Sauce",
    singlePrice: true,
    priceNum: 5,
    priceWord: "five.fifty",
    allergenes: [1, 12],
    category: 5,
  },
  {
    id: 39,
    label: "Chicken Tenders",
    singlePrice: true,
    priceNum: 6,
    priceWord: "six",
    allergenes: [1, 3, 4, 6, 7, 9, 10, 14],
    searchWord: [38],
    category: 5,
  },
  {
    id: 40,
    label: "Fried Cream",
    singlePrice: true,
    priceNum: 6,
    priceWord: "six",
    allergenes: [1, 3, 7],
    category: 5,
  },
  {
    id: 6,
    label: "Oliva Ascolana coated in Truffle",
    singlePrice: true,
    priceNum: 7,
    priceWord: "seven",
    allergenes: [1, 3, 4, 6, 7, 9, 10, 12, 14],
    category: 1,
    advised: "Aromatic White Wine",
    advisedIcon: "wineGlassRed",
  },
  {
    id: 7,
    label: "Potato Croquette stuffed with mozzarella",
    singlePrice: true,
    priceNum: 7,
    priceWord: "seven",
    allergenes: [1, 3, 4, 7, 9],
    ingredients: [],
    searchWord: [7],
    category: 1,
    advised: "Structured Red Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 88,
    label: "Mama's Grand Fry-Up",
    secondLabel:
      "Croquettes, fried cream, olives, tempura vegetables, french fries, and spicy devils",
    singlePrice: true,
    priceNum: 13,
    priceWord: "thirteen",
    allergenes: [1, 3, 4, 6, 7, 9, 10, 12, 14],
    category: 1,
    advised: "Structured Red Wine",
    advisedIcon: "wineGlassWhite",
  },
];

export const TARTARE_EN: IFoodProps[] = [
  {
    id: 8,
    label: "Salmon",
    singlePrice: true,
    priceNum: 12,
    priceWord: "twelve",
    allergenes: [4, 7, 12],
    ingredients: [91, 92, 93],
    searchWord: [2, 10],
    category: 1,
    advised: "Aromatic White Wine, Semi-Aromatic White Wine, or Prosecco",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 9,
    label: "Tuna",
    singlePrice: true,
    priceNum: 12,
    priceWord: "twelve",
    allergenes: [4, 6, 7, 11],
    ingredients: [32, 95, 82],
    searchWord: [2, 11],
    category: 1,
    advised: "Sparkling Wine or Aromatic White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 10,
    label: "Imperial Prawn",
    singlePrice: true,
    priceNum: 12,
    priceWord: "twelve",
    allergenes: [2, 7, 12],
    ingredients: [90, 32, 96],
    searchWord: [2, 12],
    category: 1,
    advised: "Sparkling Wine or Aromatic White Wine",
    advisedIcon: "wineGlassWhite",
  },

  {
    id: 11,
    label: "Scottona Marchigiana",
    singlePrice: true,
    priceNum: 12,
    priceWord: "twelve",
    allergenes: [1, 3, 6, 7, 10, 11, 12, 13],
    ingredients: [23, 97, 45],
    searchWord: [27],
    category: 1,
    advised: "Structured White Wine",
    advisedIcon: "wineGlassWhite",
  },
];

export const PIZZE_LE_TRADIZIONALI_EN: IFoodProps[] = [
  {
    id: 12,
    label: "Margherita",
    singlePrice: true,
    priceNum: 8,
    priceWord: "eight",
    allergenes: [1, 7],
    ingredients: [1, 2, 3, 4],
    searchWord: [13, 14, 15],
    category: 2,
    advised: "White Wine or Rosé Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 92,
    label: "Bufalina",
    singlePrice: true,
    priceNum: 9,
    priceWord: "nine",
    allergenes: [1, 7],
    ingredients: [1, 6, 112, 4],
    searchWord: [12, 14, 15],
    category: 2,
  },
  {
    id: 13,
    label: "Sofì",
    singlePrice: true,
    priceNum: 9,
    priceWord: "nine",
    allergenes: [1, 7],
    ingredients: [5, 1, 2, 3, 4],
    searchWord: [14, 13, 15],
    category: 2,
    advised: "Vino spumantizzato",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 14,
    label: "Sbagliata",
    singlePrice: true,
    priceNum: 11,
    priceWord: "undici",
    allergenes: [1, 7, 9],
    ingredients: [102, 113, 7],
    searchWord: [14, 13, 16, 39],
    category: 2,
    advised: "White Wine or Rosé Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 15,
    label: "Pomod'oro",
    singlePrice: true,
    priceNum: 10,
    priceWord: "ten",
    allergenes: [1, 7, 9],
    ingredients: [8, 6, 114],
    searchWord: [14, 13, 16],
    category: 2,
    advised: "Medium-Structured White Wine or Sparkling Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 16,
    label: "Marinara",
    singlePrice: true,
    priceNum: 12,
    priceWord: "twelve",
    allergenes: [1, 4, 7, 12],
    ingredients: [1, 6, 9, 10, 11],
    searchWord: [14, 13, 16, 17, 3],
    category: 2,
    advised: "Structured White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 17,
    label: "Capriccio",
    singlePrice: true,
    priceNum: 13,
    priceWord: "thirteen",
    allergenes: [1, 7, 12],
    ingredients: [115, 2, 13, 116, 10],
    searchWord: [18, 13, 15, 19, 20, 17],
    category: 2,
    advised: "Medium-Structured Red Wine or Rosé Wine",
    advisedIcon: "wineGlassRed",
  },
  {
    id: 18,
    label: "Un Viaggio a Praga",
    singlePrice: true,
    priceNum: 11,
    priceWord: "eleven",
    allergenes: [1, 3, 7, 8, 10, 12],
    ingredients: [117, 2, 118, 119],
    searchWord: [21, 15, 13, 44, 45],
    category: 2,
    advised: "Red Beer",
    advisedIcon: "beerGlass",
  },
  {
    id: 26,
    label: "4 Formaggi 2.0",
    singlePrice: true,
    priceNum: 11,
    priceWord: "eleven",
    allergenes: [1, 7, 12],
    ingredients: [2, 120, 38, 45, 30, 48],
    searchWord: [13, 15, 31, 32],
    category: 2,
    advised: "Sparkling Wine or Structured Red Wine or Red Beer",
    advisedIcon: "wineGlassWhite",
  },
];

export const PIZZE_SENZA_GLUTINE_EN: IFoodProps[] = [
  {
    id: 93,
    label: "Margherita",
    singlePrice: true,
    priceNum: 10,
    priceWord: "ten",
    allergenes: [6, 7, 8, 10, 11],
    ingredients: [1, 2, 3, 4],
    searchWord: [13, 14, 15],
    category: 2,
    advised: "White Wine or Rosé Wine",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 94,
    label: "Bufalina",
    singlePrice: true,
    priceNum: 11,
    priceWord: "eleven",
    allergenes: [6, 7, 8, 10, 11],
    ingredients: [1, 6, 112, 4],
    searchWord: [12, 14, 15],
    category: 2,
    glutenFree: true,
  },
  {
    id: 95,
    label: "Sbagliata",
    singlePrice: true,
    priceNum: 13,
    priceWord: "thirteen",
    allergenes: [6, 7, 8, 9, 10, 11],
    ingredients: [102, 113, 7],
    searchWord: [14, 13, 16, 39],
    category: 2,
    advised: "White Wine or Rosé Wine",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 96,
    label: "Pomod'oro",
    singlePrice: true,
    priceNum: 11,
    priceWord: "eleven",
    allergenes: [6, 7, 8, 9, 10, 11],
    ingredients: [8, 6, 7],
    searchWord: [14, 13, 16],
    category: 2,
    advised: "Medium-Structured White Wine or Sparkling Wine",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 97,
    label: "Marinara",
    singlePrice: true,
    priceNum: 14,
    priceWord: "fourteen",
    allergenes: [4, 6, 7, 8, 10, 11, 12],
    ingredients: [1, 6, 9, 10, 11],
    searchWord: [14, 13, 16, 17, 3],
    category: 2,
    advised: "Structured White Wine",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 99,
    label: "Costiera",
    singlePrice: true,
    priceNum: 16,
    priceWord: "sixteen",
    allergenes: [4, 6, 7, 8, 10, 11],
    ingredients: [8, 6, 27, 28, 29, 30],
    searchWord: [14, 13, 16, 11],
    category: 2,
    advised: "Sparkling Wine or Aromatic White Wine",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 100,
    label: "La Preferita",
    singlePrice: true,
    priceNum: 14,
    priceWord: "fourteen",
    allergenes: [6, 7, 8, 10, 11],
    ingredients: [49, 2, 50, 32, 51],
    searchWord: [21, 13, 15, 19],
    category: 2,
    advised: "Sparkling White Wine or Rosé Wine",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 101,
    label: "Mortazza",
    singlePrice: true,
    priceNum: 15,
    priceWord: "fifteen",
    allergenes: [6, 7, 8, 10, 11, 12],
    ingredients: [2, 37, 32, 98],
    searchWord: [21, 13, 15, 29, 35],
    category: 2,
    advised: "Sparkling Wine",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
  {
    id: 98,
    label: "4 Formaggi 2.0",
    singlePrice: true,
    priceNum: 13,
    priceWord: "thirteen",
    allergenes: [6, 7, 8, 10, 11, 12],
    ingredients: [2, 120, 126, 45, 30, 48],
    searchWord: [13, 15, 31, 32],
    category: 2,
    advised: "Sparkling Wine or Structured Red Wine or Red Beer",
    advisedIcon: "wineGlassWhite",
    glutenFree: true,
  },
];

export const PIZZE_LE_CONTEMPORANEE_EN: IFoodProps[] = [
  {
    id: 401,
    label: "Autumn Quinox",
    singlePrice: true,
    priceNum: 15,
    priceWord: "fifteen",
    allergenes: [1, 7, 8, 12],
    ingredients: [132, 133, 134, 42],
    category: 2,
  },
  {
    id: 400,
    label: "Che Ficata",
    singlePrice: true,
    priceNum: 15,
    priceWord: "fifteen",
    allergenes: [1, 5, 7, 8, 11, 12],
    ingredients: [129, 126, 130, 131],
    category: 2,
  },
  {
    id: 89,
    label: "Nduja Assassina",
    singlePrice: true,
    priceNum: 12,
    priceWord: "twelve",
    allergenes: [1, 7, 12],
    ingredients: [102, 32, 103, 104, 105],
    searchWord: [41, 17],
    category: 2,
  },
  {
    id: 90,
    label: "CarboNera",
    singlePrice: true,
    priceNum: 14,
    priceWord: "fourteen",
    allergenes: [1, 3, 7, 12],
    ingredients: [2, 125, 38, 42, 96, 30],
    searchWord: [41, 17],
    category: 2,
  },
  {
    id: 91,
    label: "Salmo...Nel Bosco",
    singlePrice: true,
    priceNum: 15,
    priceWord: "fifteen",
    allergenes: [1, 4, 7, 11, 12],
    ingredients: [106, 83, 107, 108, 32, 109],
    searchWord: [10, 42],
    category: 2,
  },
  {
    id: 22,
    label: "Costiera",
    singlePrice: true,
    priceNum: 14,
    priceWord: "fourteen",
    allergenes: [1, 4, 7],
    ingredients: [8, 6, 27, 28, 29, 30],
    searchWord: [14, 13, 16, 11],
    category: 2,
    advised: "Sparkling Wine or Aromatic White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 23,
    label: "Stasera mi butto",
    singlePrice: true,
    priceNum: 15,
    priceWord: "fifteen",
    allergenes: [1, 3, 4, 6, 7, 10, 12, 14],
    ingredients: [31, 32, 100, 101],
    searchWord: [4, 28, 37],
    category: 2,
    advised: "Structured White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 24,
    label: "Mortazza",
    singlePrice: true,
    priceNum: 12,
    priceWord: "twelve",
    allergenes: [1, 7, 8, 12],
    ingredients: [2, 37, 38, 98],
    searchWord: [21, 13, 15, 29, 35],
    category: 2,
    advised: "Sparkling White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 25,
    label: "Mamà",
    singlePrice: true,
    priceNum: 16,
    priceWord: "sixteen",
    allergenes: [1, 2, 7, 8, 12],
    ingredients: [39, 32, 40, 41, 42],
    searchWord: [30, 21, 12, 34],
    category: 2,
    advised: "Sparkling Rosé Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 20,
    label: "Merenda del Pastore",
    singlePrice: true,
    priceNum: 13,
    priceWord: "thirteen",
    allergenes: [1, 3, 6, 7, 8, 10, 11, 12, 13],
    ingredients: [2, 21, 22, 99, 94],
    searchWord: [13, 15, 24],
    category: 2,
    advised: "Local Red Wine or Structured White Wine",
    advisedIcon: "wineGlassRed",
  },
  {
    id: 27,
    label: "La Preferita",
    singlePrice: true,
    priceNum: 12,
    priceWord: "twelve",
    allergenes: [1, 7],
    ingredients: [49, 2, 50, 32, 51],
    searchWord: [21, 13, 15, 19],
    category: 2,
    advised: "Sparkling White Wine or Rosé Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 19,
    label: "Ortogolosa",
    singlePrice: true,
    priceNum: 12,
    priceWord: "twelve",
    allergenes: [1, 7, 8, 12],
    ingredients: [2, 49, 22, 110, 104, 111, 3],
    searchWord: [15, 43, 14, 17],
    category: 2,
    advised: "Structured Red Wine",
    advisedIcon: "wineGlassRed",
  },

  {
    id: 21,
    label: "Porca Norma",
    singlePrice: true,
    priceNum: 11,
    priceWord: "eleven",
    allergenes: [1, 7, 9],
    ingredients: [1, 2, 24, 25, 99, 7],
    searchWord: [13, 14, 15, 24, 7, 25, 26, 27],
    category: 2,
    advised: "Red Beer, Red Wine, Rosé Wine, or Sparkling Wine",
    advisedIcon: "beerGlass",
  },

  // {
  //   id: 28,
  //   label: "Buonconsiglio",
  //   singlePrice: true,
  //   priceNum: 14,
  //   priceWord: "quattordici",
  //   allergenes: [1, 7],
  //   ingredients: [32, 52, 53, 45, 54],
  //   searchWord: [7],
  //   category: 2,
  //   advised: "Vino bianco aromatico",
  //   advisedIcon: "wineGlassWhite",
  // },
];

export const SEI_SPICCHI_EN: IFoodProps[] = [
  {
    id: 102,
    label: "Alto Adige",
    singlePrice: true,
    priceNum: 16,
    priceWord: "sixteen",
    allergenes: [1, 7, 9, 12],
    ingredients: [52, 32, 7, 54],
    searchWord: [8],
    category: 3,
  },
  {
    id: 29,
    label: "Red Salmo",
    singlePrice: true,
    priceNum: 17,
    priceWord: "seventeen",
    allergenes: [1, 3, 4, 7, 8, 10, 12],
    ingredients: [121, 73, 101, 66],
    searchWord: [33, 10, 27],
    category: 3,
    advised:
      "Aromatic White Wine or Semi-Aromatic White Wine or Sparkling Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 30,
    label: "Tunataki",
    singlePrice: true,
    priceNum: 18,
    priceWord: "eighteen",
    allergenes: [1, 3, 4, 6, 7, 8, 9, 10, 11, 12],
    ingredients: [32, 76, 108, 77, 122],
    searchWord: [11],
    category: 3,
    advised: "Sparkling White Wine or Aromatic White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 31,
    label: "Squiddy",
    singlePrice: true,
    priceNum: 17,
    priceWord: "seventeen",
    allergenes: [1, 4, 7, 12, 14],
    ingredients: [38, 79, 96, 110],
    searchWord: [9, 34, 35],
    category: 3,
    advised: "Structured White Wine or Sparkling Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 32,
    label: "La Marchigiana",
    singlePrice: true,
    priceNum: 18,
    priceWord: "eighteen",
    allergenes: [1, 3, 7, 12],
    ingredients: [81, 123, 42],
    category: 3,
    advised: "Structured White Wine",
    advisedIcon: "wineGlassWhite",
  },
  {
    id: 33,
    label: "Pork Super-crusty",
    singlePrice: true,
    priceNum: 16,
    priceWord: "sixteen",
    allergenes: [1, 7, 12],
    ingredients: [86, 124, 115],
    searchWord: [36, 23],
    category: 3,
    advised: "Red Wine or Red Beer",
    advisedIcon: "wineGlassRed",
  },
];

export const DOLCI_EN: IFoodProps[] = [
  {
    id: 41,
    label: "Homemade artisan plumpudding with chocolate and exotic fruits",
    singlePrice: true,
    priceNum: 6,
    priceWord: "six",
    allergenes: [1, 3, 5, 7, 8],
    advised: "Casalis Douhet passito 900",
    advisedIcon: "wineGlassWhite",
    category: 6,
  },

  {
    id: 41,
    label: "Cheesecake",
    singlePrice: true,
    priceNum: 6,
    priceWord: "six",
    allergenes: [1, 3, 7],
    advised: "Casalis Douhet passito 900",
    advisedIcon: "wineGlassWhite",
    category: 6,
  },
  // {
  //   id: 44,
  //   label: "Salted Caramel Semifreddo",
  //   singlePrice: true,
  //   priceNum: 6,
  //   priceWord: "six",
  //   allergenes: [5, 7],
  //   advised: "Casalis Douhet passito 900",
  //   advisedIcon: "wineGlassWhite",
  //   category: 6,
  // },
  // {
  //   id: 34,
  //   label: "Coconut Fondant",
  //   singlePrice: true,
  //   priceNum: 6,
  //   priceWord: "six",
  //   allergenes: [7, 8, 12],
  //   advised: "Casalis Douhet passito 900",
  //   advisedIcon: "wineGlassWhite",
  //   category: 6,
  // },
  {
    id: 42,
    label: "Sferamisù",
    singlePrice: true,
    priceNum: 6,
    priceWord: "six",
    allergenes: [1, 3, 7],
    advised: "Casalis Douhet passito 900",
    advisedIcon: "wineGlassWhite",
    category: 6,
  },
  // {
  //   id: 43,
  //   label: "Sorbetto al caffè",
  //   singlePrice: true,
  //   priceNum: 3,
  //   priceWord: "tre",
  //   allergenes: [7],
  //   advised: "Casalis Douhet passito 900",
  //   advisedIcon: "wineGlassWhite",
  //   category: 6,
  // },

  {
    id: 45,
    label: "Mini chocolate cake with liquid core",
    singlePrice: true,
    priceNum: 6,
    priceWord: "six",
    allergenes: [3, 6, 7, 8],
    advised: "Casalis Douhet passito 900",
    advisedIcon: "wineGlassWhite",
    category: 6,
  },
];

export const BOLLICINE_EN: IFoodProps[] = [
  {
    id: 120,
    label: `"Vernaccianera" Spumante Vernaccia di Serrapetrona DOCG Secca`,
    methods: "Classic",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [23],
    pricesWord: ["twenty-three"],
    pricesIconRef: ["wineBottle", "spumanteGlass"],
  },
  {
    id: 46,
    label: "Prosecco Superiore DOCG Extra Dry Villa Giustiniani",
    methods: "Classic",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [18],
    pricesWord: ["eighteen"],
    pricesIconRef: ["wineBottle", "spumanteGlass"],
  },
  {
    id: 103,
    label: "Centanni Brut",
    secondLabel: "Passerina BIO",
    methods: "Charmat",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [23],
    pricesWord: ["twenty-three"],
    pricesIconRef: ["wineBottle", "spumanteGlass"],
  },
  {
    id: 104,
    label: "Luigi Ghislieri Brut",
    secondLabel: "Verdicchio di Jesi BIO",
    methods: "Classic",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [29],
    pricesWord: ["twenty-nine"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 105,
    label: 'Gobbi e Mannocchi "Osso"',
    secondLabel: "Extra Brut Rosé",
    methods: "Ancestral",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [27],
    pricesWord: ["twenty-seven"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 106,
    label: 'Tavignano "Il Pestifero"',
    secondLabel: "Bianco Marche BIO",
    methods: "Unfiltered",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [22],
    pricesWord: ["twenty-two"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 107,
    label: "Saputi Ribona",
    secondLabel: "Extra Dry DOC",
    methods: "Charmat",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [21],
    pricesWord: ["twenty-one"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 108,
    label: "Arthur Metz",
    secondLabel: `Cremant D'Alsace Brut "Perle Noire"`,
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [30],
    pricesWord: ["thirty"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 109,
    label: "Bruno Ceci",
    secondLabel: `Lambrusco Spumante brut`,
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [23],
    pricesWord: ["twenty-three"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 52,
    label: "Erbaluce Pas Dose Masilé DOCG",
    methods: "Classic",
    singlePrice: false,
    pricesNum: [40],
    pricesWord: ["forty"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 110,
    label: "Forchir Ribolla Gialla",
    secondLabel: `Brut Nature`,
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [24],
    pricesWord: ["twenty-four"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 111,
    label: "Corteaura Franciacorta",
    secondLabel: `Brut DOCG`,
    methods: "Classic",
    singlePrice: false,
    pricesIconSize: ["35px"],
    pricesNum: [36],
    pricesWord: ["thirty-six"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 54,
    label: "La Montina Franciacorta Extra Brut Rosé Millesimato",
    methods: "Classic",
    singlePrice: false,
    pricesNum: [45],
    pricesWord: ["forty-five"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
];

export const CHAMPAGNE_EN: IFoodProps[] = [
  {
    id: 55,
    label: "Champagne Jean de la Fontaine brut L'Eloquente",
    secondLabel: "73% Pinot Mounier - 16% Chardonnay - 11% Pinot Nero",
    methods: "Classic",
    singlePrice: false,
    pricesNum: [50],
    pricesIconSize: ["35px", "30px"],
    pricesWord: ["fifty"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 56,
    label: "Champagne Gamet Rive Droite Blanc de noirs Vallée de la Marne",
    secondLabel: "60% Pinot Mounier - 40% Pinot Nero",
    methods: "Classic",
    singlePrice: false,
    pricesNum: [60],
    pricesWord: ["sixty"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 57,
    label: "Champagne Brice Heritage brut Grande Cru Bouzy",
    secondLabel: "30% Chardonnay - 70% Pinot Nero",
    methods: "Classic",
    singlePrice: false,
    pricesNum: [60],
    pricesWord: ["sixty"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
];

export const VINI_BIANCHI_EN: IFoodProps[] = [
  {
    id: 59,
    label: `Tavignano Verdicchio dei Castelli di Jesi Sup. Doc "Villa Torre"`,
    singlePrice: false,
    pricesNum: [19],
    pricesIconSize: ["35px"],
    pricesWord: ["nineteen"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 60,
    label: `Borgo Paglianetto Verdicchio di Matelica DOC "Terravignata"`,
    singlePrice: false,
    pricesNum: [18],
    pricesWord: ["eighteen"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 61,
    label: `Centanni Passerina DOCG "Cimula"`,
    singlePrice: false,
    pricesNum: [20],
    pricesWord: ["twenty"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 62,
    label: `Collevite Pecorino Falerio DOCG BIO "Villa Piatti" `,
    singlePrice: false,
    pricesNum: [21],
    pricesWord: ["twenty-one"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 63,
    label: "Girlan Chardonnay Alto Adige DOC",
    singlePrice: false,
    pricesNum: [24],
    pricesWord: ["twenty-four"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 64,
    label: "Girlan Sauvignon INDRA",
    singlePrice: false,
    pricesNum: [26],
    pricesWord: ["twenty-six"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 65,
    label: `Erste&Neue Gewürztraminer DOC`,
    singlePrice: false,
    pricesNum: [26],
    pricesWord: ["twenty-six"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 112,
    label: `Abtei Himmerod Riesling Trocken "Vecchie Vigne" Mosella`,
    singlePrice: false,
    pricesNum: [24],
    pricesWord: ["twenty-four"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
];

export const VINI_ROSSI_EN: IFoodProps[] = [
  {
    id: 66,
    label: `Vicari Lacrima di Morro D'Alba "Da sempre"`,
    singlePrice: false,
    pricesNum: [19],
    pricesWord: ["nineteen"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 67,
    label: "Lanari Rosso Conero DOC",
    singlePrice: false,
    pricesNum: [21],
    pricesWord: ["twenty-one"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 68,
    label: `Tenuta S. Antonio "Monti Garbi" Valpolicella Ripasso Superiore DOC`,
    singlePrice: false,
    pricesNum: [35],
    pricesWord: ["thirty-five"],
    pricesIconSize: ["35px", "25px"],
    pricesIconRef: ["wineBottle", "wineGlassRed"],
  },
  {
    id: 113,
    label: `Rosso Dolomiti IGT "T Rosso"`,
    singlePrice: false,
    pricesNum: [18],
    pricesWord: ["eighteen"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
  {
    id: 114,
    label: `Centanni "Floralia" Montepulciano Rosato Marche`,
    singlePrice: false,
    pricesNum: [20],
    pricesWord: ["twenty"],
    pricesIconSize: ["35px"],
    pricesIconRef: ["wineBottle"],
  },
];

export const BEVANDE_EN: IFoodProps[] = [
  {
    id: 69,
    label: "Still/Sparkling Water",
    singlePrice: false,
    pricesNum: [2],
    pricesWord: ["two"],
    pricesUnit: ["75 cl"],
    pricesIconSize: ["20px"],
    pricesIconRef: ["waterIcon"],
  },
  {
    id: 115,
    label: "Prosecco on Tap",
    singlePrice: false,
    pricesNum: [5, 11],
    pricesWord: ["eleven", "five"],
    pricesUnit: ["1 lt", "Glass"],
    pricesIconSize: ["20px"],
    pricesIconRef: ["wineJugSmall", "wineGlassWhite"],
  },
  {
    id: 71,
    label: "Draft Beer",
    secondLabel: "Estrella Galicia",
    singlePrice: false,
    pricesNum: [10, 5, 3],
    pricesWord: ["ten", "five", "three"],
    pricesUnit: ["1 lt", "0.4 cl", "0.25 cl"],
    pricesIconSize: ["25px", "21px", "18px"],
    pricesIconRef: ["beerGlass", "beerGlass", "beerGlass"],
  },
  {
    id: 70,
    label: "Beer",
    secondLabel: "Rotating Selection Based on Seasonality",
    singlePrice: false,
    pricesNum: [11, 4],
    pricesWord: ["eleven", "four"],
    pricesIconSize: ["30px", "20px"],
    pricesIconRef: ["beerGlass", "beerGlass"],
    pricesUnit: ["1 lt", "0.33 cl"],
  },
  {
    id: 72,
    label: "Coca Cola on Tap",
    singlePrice: false,
    pricesNum: [10, 5, 3],
    pricesWord: ["ten", "five", "three"],
    pricesUnit: ["1 lt", "0.5 cl", "0.3 cl"],
    pricesIconSize: ["25px", "21px", "18px"],
    pricesIconRef: ["beerGlass", "beerGlass", "beerGlass"],
  },
  {
    id: 73,
    label: "Coca Cola",
    singlePrice: false,
    pricesNum: [3, 9],
    pricesWord: ["three.fifty"],
    pricesUnit: ["0.33 lt"],
    pricesIconSize: ["30px"],
    pricesIconRef: ["pepsiBottle"],
  },
  {
    id: 74,
    label: "Coca Cola Zero",
    singlePrice: false,
    pricesNum: [3, 9],
    pricesWord: ["three.fifty"],
    pricesUnit: ["0.33 lt"],
    pricesIconSize: ["30px"],
    pricesIconRef: ["pepsiBottle"],
  },
  {
    id: 75,
    label: "Fanta",
    singlePrice: false,
    pricesNum: [3, 9],
    pricesWord: ["three.fifty"],
    pricesUnit: ["0.33 lt"],
    pricesIconSize: ["30px"],
    pricesIconRef: ["pepsiBottle"],
  },
  {
    id: 76,
    label: "Sprite",
    singlePrice: false,
    pricesNum: [3, 9],
    pricesWord: ["three.fifty"],
    pricesUnit: ["0.33 lt"],
    pricesIconSize: ["30px"],
    pricesIconRef: ["pepsiBottle"],
  },
  {
    id: 115,
    label: "Digestifs",
    singlePrice: false,
    pricesNum: [3.5],
    pricesWord: ["three.fifty"],
    pricesIconSize: ["30px"],
  },
  {
    id: 115,
    label: "Coffee",
    singlePrice: false,
    pricesNum: [1.5],
    pricesWord: ["one.fifty"],
    pricesIconSize: ["30px"],
  },
  {
    id: 116,
    label: "Nutella Coffee",
    singlePrice: false,
    pricesNum: [2],
    pricesWord: ["two"],
    pricesIconSize: ["30px"],
  },
];

export const BIRRE_ARTIGIANLI_EN: IFoodProps[] = [
  {
    id: 77,
    label: "Estrella Galicia 0.0",
    singlePrice: false,
    pricesNum: [5],
    pricesWord: ["five"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 78,
    label: "Estrella Galicia Sin Gluten",
    singlePrice: false,
    pricesNum: [5],
    pricesWord: ["five"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 79,
    label: "Blonde Ale",
    secondLabel: "IBeer Brewery",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["six"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 80,
    label: "Hemp Blanche - Canapa",
    secondLabel: "IBeer Brewery",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["six"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 117,
    label: "American IPA",
    secondLabel: "IBeer Brewery",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["six"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 118,
    label: "Red Ale",
    secondLabel: "IBeer Brewery",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["six"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
  {
    id: 119,
    label: "Weiss",
    secondLabel: "IBeer Brewery",
    singlePrice: false,
    pricesNum: [8],
    pricesWord: ["eight"],
    pricesIconRef: ["bottleBeer"],
    pricesIconSize: ["30px"],
  },
];

export const GIN_TONIC_EN: IFoodProps[] = [
  {
    id: 81,
    label: "Gin and Tonic",
    singlePrice: false,
    pricesNum: [8],
    pricesWord: ["eight"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },
  {
    id: 82,
    label: "Spritz",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["six"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },
  {
    id: 83,
    label: "Campari Spritz",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["six"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },
  {
    id: 84,
    label: "Americano",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["six"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },

  {
    id: 86,
    label: "Negroni Sbagliato",
    singlePrice: false,
    pricesNum: [6],
    pricesWord: ["six"],
    pricesIconRef: ["cocktailGlass"],
    pricesIconSize: ["25px"],
  },
];

export const INGREDIENTS_EN: IIngredientsProps[] = [
  {
    id: 1,
    label: "San Marzano Peeled Tomatoes",
    icon: "icon-park-twotone:tomato",
  },
  {
    id: 2,
    label: "Fiordilatte Mozzarella from Caseificio Il Faro",
    icon: "icon-park-twotone:tomato",
  },
  {
    id: 3,
    label: "Baked Basil",
    icon: "",
  },
  {
    id: 4,
    label: "Extra Virgin Olive Oil",
    icon: "",
  },
  {
    id: 5,
    label: "Fried Pizzella Baked Again",
    icon: "",
  },
  {
    id: 6,
    label: "Buffalo Mozzarella Cherries",
    icon: "",
  },
  {
    id: 7,
    label: "Fresh Basil Coulis",
    icon: "",
  },
  {
    id: 8,
    label: "Yellow Datterino Tomato Purée",
    icon: "",
  },
  {
    id: 9,
    label: "Adriatic Anchovies",
    icon: "",
  },
  {
    id: 10,
    label: "Taggiasca Olives",
    icon: "",
  },
  {
    id: 11,
    label: "Sicilian Oregano",
    icon: "",
  },
  {
    id: 12,
    label: "Artichoke Cream",
    icon: "",
  },
  {
    id: 13,
    label: "Aged Prosciutto Crudo from Assisi",
    icon: "",
  },
  {
    id: 14,
    label: "Chiodini Mushrooms",
    icon: "",
  },
  {
    id: 15,
    label: "Oven-Baked Crusted Potatoes",
    icon: "",
  },
  {
    id: 16,
    label: "Würstel with BBQ Sauce",
    icon: "",
  },
  {
    id: 17,
    label: "Fermented Black Garlic Cream",
    icon: "",
  },
  {
    id: 18,
    label: "Worcestershire Sauce",
    icon: "",
  },
  {
    id: 19,
    label: "Tabasco",
    icon: "",
  },
  {
    id: 20,
    label: "Crusco Pepper",
    icon: "",
  },
  {
    id: 21,
    label: "Ciauscolo di Muccia PGI",
    icon: "",
  },
  {
    id: 22,
    label: "Sautéed Field Herbs",
    icon: "",
  },
  {
    id: 23,
    label: "Taralli Crumble",
    icon: "",
  },
  {
    id: 24,
    label: "Sausage Ragù",
    icon: "",
  },
  {
    id: 25,
    label: "Fried Eggplant",
    icon: "",
  },
  {
    id: 26,
    label: "Ricotta Salata",
    icon: "",
  },
  {
    id: 27,
    label: "Tuna in Porchetta",
    icon: "",
  },
  {
    id: 28,
    label: "Tropea Red Onion",
    icon: "",
  },
  {
    id: 29,
    label: "Wild Fennel",
    icon: "",
  },
  {
    id: 30,
    label: "Whole Pepper",
    icon: "",
  },
  {
    id: 31,
    label: "Edamame Hummus",
    icon: "",
  },
  {
    id: 32,
    label: "Homemade Buffalo Stracciatella",
    icon: "",
  },
  {
    id: 33,
    label: "Grilled Seared Squid",
    icon: "",
  },
  {
    id: 34,
    label: "Beetroot Mousse",
    icon: "",
  },
  {
    id: 35,
    label: "Mullet Bottarga",
    icon: "",
  },
  {
    id: 36,
    label: "Black Summer Truffle Potato Cream",
    icon: "",
  },
  {
    id: 37,
    label: "Bologna Mortadella PGI",
    icon: "",
  },
  {
    id: 38,
    label: "Pecorino Romano DOP Foam",
    icon: "",
  },
  {
    id: 39,
    label: "Purple Potato Velouté",
    icon: "",
  },
  {
    id: 40,
    label: "Adriatic Imperial Shrimp Tartare",
    icon: "",
  },
  {
    id: 41,
    label: "Guanciale Chips",
    icon: "",
  },
  {
    id: 42,
    label: "Black Summer Truffle 'Trivelli'",
    icon: "",
  },
  {
    id: 43,
    label: "Stracchino",
    icon: "",
  },
  {
    id: 44,
    label: "Gorgonzola",
    icon: "",
  },
  {
    id: 45,
    label: "Parmesan Wafer",
    icon: "",
  },
  {
    id: 46,
    label: "Pecorino Romano DOP Cream",
    icon: "",
  },
  {
    id: 47,
    label: "Pepper",
    icon: "",
  },
  {
    id: 48,
    label: "Cinnamon",
    icon: "",
  },
  {
    id: 49,
    label: "Smashed Potato",
    icon: "",
  },
  {
    id: 50,
    label: "Crispy Prosciutto Crudo",
    icon: "",
  },
  {
    id: 51,
    label: "Rosemary Infused Extra Virgin Olive Oil",
    icon: "",
  },
  {
    id: 52,
    label: "Carpaccio of Carne Salada",
    icon: "",
  },
  {
    id: 53,
    label: "Cream of Aromatic Herbs",
    icon: "",
  },
  {
    id: 54,
    label: "Lemon Zest",
    icon: "",
  },
  {
    id: 55,
    label: "Panko",
    icon: "",
  },
  {
    id: 56,
    label: "Basil Mayo",
    icon: "",
  },
  {
    id: 57,
    label: "Pink Pepper",
    icon: "",
  },
  {
    id: 58,
    label: "Lime",
    icon: "",
  },
  {
    id: 59,
    label: "Pugliese Tarallo Crumble",
    icon: "",
  },
  {
    id: 60,
    label: "Black Summer Truffle Sauce",
    icon: "",
  },
  {
    id: 61,
    label: "Sun-dried Cherry Tomatoes",
    icon: "",
  },
  {
    id: 62,
    label: "Balsamic Glaze",
    icon: "",
  },
  {
    id: 63,
    label: "Walnuts",
    icon: "",
  },
  {
    id: 64,
    label: "Avocado",
    icon: "",
  },
  {
    id: 65,
    label: "Sesame Seeds",
    icon: "",
  },
  {
    id: 66,
    label: "Edible Flowers",
    icon: "",
  },
  {
    id: 67,
    label: "Black Venus Rice",
    icon: "",
  },
  {
    id: 68,
    label: "Mixed Fruit",
    icon: "",
  },
  {
    id: 69,
    label: "Buffalo Mozzarella",
    icon: "",
  },
  {
    id: 70,
    label: "Brusco Bread",
    icon: "",
  },
  {
    id: 71,
    label: "Crunchy Onion",
    icon: "",
  },
  {
    id: 72,
    label: "Creamy Avocado",
    icon: "",
  },
  {
    id: 73,
    label: "Salmon Gravlax",
    icon: "",
  },
  {
    id: 74,
    label: "Ricotta Drops",
    icon: "",
  },
  {
    id: 75,
    label: "Toasted Almonds",
    icon: "",
  },
  {
    id: 76,
    label: "Tuna Tartare",
    icon: "",
  },
  {
    id: 77,
    label: "Wakame Seaweed",
    icon: "",
  },
  {
    id: 78,
    label: "Soy Mayo",
    icon: "",
  },
  {
    id: 127,
    label: "Beetroot Mousse",
    icon: "",
  },
  {
    id: 79,
    label: "Double Cooked Octopus",
    icon: "",
  },
  {
    id: 80,
    label: "Creamy Pecorino Romano DOP Fondue",
    icon: "",
  },
  {
    id: 81,
    label: "Tartare Scottona Marchigiana",
    icon: "",
  },
  {
    id: 82,
    label: "Black Sesame Seeds",
    icon: "",
  },
  {
    id: 83,
    label: "Valerianella",
    icon: "",
  },
  {
    id: 84,
    label: "Red Onion Chips",
    icon: "",
  },
  {
    id: 85,
    label: "Mustard Mayo",
    icon: "",
  },
  {
    id: 86,
    label: "Smoked Provola Fondue",
    icon: "",
  },
  {
    id: 87,
    label: "Pulled Pork BBQ",
    icon: "",
  },
  {
    id: 88,
    label: "Edamame Cream",
    icon: "",
  },
  {
    id: 89,
    label: "Seitan in Tempura",
    icon: "",
  },
  {
    id: 90,
    label: "Purple Potato",
    icon: "",
  },
  {
    id: 91,
    label: "Ricotta Mousse",
    icon: "",
  },
  {
    id: 92,
    label: "Cucumber Carpaccio",
    icon: "",
  },
  {
    id: 93,
    label: "Dehydrated Mango Chips",
    icon: "",
  },
  {
    id: 94,
    label: "Crumbled Tarallo",
    icon: "",
  },
  {
    id: 95,
    label: "Soy Sauce",
    icon: "",
  },
  {
    id: 96,
    label: "Crispy Guanciale",
    icon: "",
  },
  {
    id: 97,
    label: "Truffle Ricotta",
    icon: "",
  },
  {
    id: 98,
    label: "Pistacchio Crumble",
    icon: "",
  },
  {
    id: 99,
    label: "Salted Ricotta from Caseificio Il Faro",
    icon: "",
  },
  {
    id: 100,
    label: "Fried Calamari",
    icon: "",
  },
  {
    id: 101,
    label: "Citrus Mayo",
    icon: "",
  },
  {
    id: 102,
    label: "Homemade grilled tomato",
    icon: "",
  },
  {
    id: 103,
    label: "Nduja from Spilinga",
    icon: "",
  },
  {
    id: 104,
    label: "Dehydrated Black Olives",
    icon: "",
  },
  {
    id: 105,
    label: "Spicy Parsley Sauce",
    icon: "",
  },
  {
    id: 106,
    label: "Smashed Purple Potato",
    icon: "",
  },
  {
    id: 107,
    label: "Wild Salmon",
    icon: "",
  },
  {
    id: 108,
    label: "Toasted Sesame Seeds",
    icon: "",
  },
  {
    id: 109,
    label: "Organic Dehydrated Cranberries",
    icon: "",
  },
  {
    id: 110,
    label: "Semi-Dried Cherry Tomatoes",
    icon: "",
  },
  {
    id: 111,
    label: "Almond Cream",
    icon: "",
  },
  {
    id: 112,
    label: "Baked Basil",
    icon: "",
  },
  {
    id: 113,
    label: "125 gr. Burrata",
    icon: "",
  },
  {
    id: 114,
    label: "Savory Basil Shortcrust",
    icon: "",
  },
  {
    id: 115,
    label: "Porcini Mushroom Cream",
    icon: "",
  },
  {
    id: 116,
    label: "Fried Artichokes",
    icon: "",
  },
  {
    id: 117,
    label: "Crispy Potato Spirals",
    icon: "",
  },
  {
    id: 118,
    label: "Ham",
    icon: "",
  },
  {
    id: 119,
    label: "Paprika Mayo",
    icon: "",
  },
  {
    id: 120,
    label: "Sweet Gorgonzola",
    icon: "",
  },
  {
    id: 121,
    label: "Almond Ricotta Cream",
    icon: "",
  },
  {
    id: 122,
    label: "Tahini Sauce",
    icon: "",
  },
  {
    id: 123,
    label: "Savory Zabaione",
    icon: "",
  },
  {
    id: 124,
    label: "House Porchetta",
    icon: "",
  },
  {
    id: 125,
    label: "Carbocream",
    icon: "",
  },
  {
    id: 126,
    label: "Pecorino Romano DOP",
    icon: "",
  },
  {
    id: 129,
    label: "Prosciutto Crudo from Parma 18 mounth",
    icon: "",
  },
  {
    id: 130,
    label: "Nuts",
    icon: "",
  },
  {
    id: 131,
    label: "Fig ice cream",
    icon: "",
  },
  {
    id: 132,
    label: "Pumpkin cream",
    icon: "",
  },
  {
    id: 133,
    label: "Smoked provola",
    icon: "",
  },
  {
    id: 134,
    label: "Sausage",
    icon: "",
  },
];

export const searchWordEn: ISearchWord[] = [
  {
    value: "1",
    label: "Mussels",
  },
  {
    value: "2",
    label: "Fish",
  },
  {
    value: "3",
    label: "Anchovies",
  },
  {
    value: "4",
    label: "Squid",
  },
  {
    value: "5",
    label: "Seafood",
  },
  {
    value: "6",
    label: "Cold Cuts and Cheeses",
  },
  {
    value: "7",
    label: "Meat",
  },
  {
    value: "8",
    label: "Carpaccio",
  },
  {
    value: "9",
    label: "Octopus",
  },
  {
    value: "10",
    label: "Salmon",
  },
  {
    value: "11",
    label: "Tuna",
  },
  {
    value: "12",
    label: "Shrimp",
  },
  {
    value: "13",
    label: "Mozzarella",
  },
  {
    value: "14",
    label: "Tomato",
  },
  {
    value: "15",
    label: "Fiordilatte Mozzarella",
  },
  {
    value: "16",
    label: "Buffalo Mozzarella",
  },
  {
    value: "17",
    label: "Olives",
  },
  {
    value: "18",
    label: "Artichokes",
  },
  {
    value: "19",
    label: "Prosciutto Crudo",
  },
  {
    value: "20",
    label: "Mushrooms",
  },
  {
    value: "21",
    label: "Potatoes",
  },
  {
    value: "22",
    label: "Sausages",
  },
  {
    value: "23",
    label: "Peppers",
  },
  {
    value: "24",
    label: "Ciauscolo",
  },
  {
    value: "25",
    label: "Sausage",
  },
  {
    value: "26",
    label: "Eggplants",
  },
  {
    value: "27",
    label: "Ricotta",
  },
  {
    value: "28",
    label: "Bottarga",
  },
  {
    value: "29",
    label: "Mortadella",
  },
  {
    value: "30",
    label: "Truffle",
  },
  {
    value: "31",
    label: "Stracchino",
  },
  {
    value: "32",
    label: "Gorgonzola",
  },
  {
    value: "33",
    label: "Avocado",
  },
  {
    value: "34",
    label: "Guanciale",
  },
  {
    value: "35",
    label: "Pecorino",
  },
  {
    value: "36",
    label: "Pulled Pork",
  },
  {
    value: "37",
    label: "Edamame",
  },
  {
    value: "38",
    label: "Chicken",
  },
  {
    value: "39",
    label: "Burrata",
  },
  {
    value: "40",
    label: "Parmesan",
  },
  {
    value: "41",
    label: "Nduja",
  },
  {
    value: "42",
    label: "Blueberries",
  },
  {
    value: "43",
    label: "Vegetables",
  },
  {
    value: "44",
    label: "Cooked Ham",
  },
  {
    value: "45",
    label: "Paprika",
  },
];

export const FOODS_EN = [
  ...ENTREE_EN,
  ...TARTARE_EN,
  ...PIZZE_LE_TRADIZIONALI_EN,
  ...PIZZE_LE_CONTEMPORANEE_EN,
  ...PIZZE_SENZA_GLUTINE_EN,
  ...SEI_SPICCHI_EN,
];

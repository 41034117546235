import { Stack, Typography } from "@mui/material";
import { Card, Page, PageContainer, TitleSection } from "components";
import { ENTREE, IFoodProps, TARTARE } from "constants/food";
import { ENTREE_EN, TARTARE_EN } from "constants/foodEn";
import { useFavorites } from "hooks/useFavorites";
import useTranslation from "i18n";
import { useGlobalStore } from "stores/global";
import { addToLocalStorage } from "utils/localStorage";

const EntreeTartare = () => {
  //hooks
  const { favorites, addFavorites, removeFavorites, showSnackbar } = useFavorites();
  const {activeLocale} = useGlobalStore()
  const {i18next} = useTranslation()
  //function
  const handleFavorite = (id: number, name: string) => {
    const isPresent = favorites.find((el) => el === id);
    if (Boolean(isPresent)) {
      showSnackbar(`${name} rimosso dai preferiti`);
      removeFavorites(id);
      addToLocalStorage(id);
      return;
    }
    if (!Boolean(isPresent)) {
      showSnackbar(`${name} aggiunto ai preferiti`);
      addFavorites(id);
      addToLocalStorage(id);
      return;
    }
  };

  const isPresentInFavorites = (id: number) => {
    const isPresent = favorites.find((el) => el === id);
    return Boolean(isPresent);
  };
  return (
    <Page title={i18next.t('head-entree')}>
      <PageContainer>
        <Stack sx={{ marginTop: "20px" }}>
          <TitleSection title={i18next.t('entree')} />
          <Stack gap="15px">
            {(activeLocale === 'it' ? ENTREE : ENTREE_EN).map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                key={`entree${index}`}
                clickFavorites={handleFavorite}
                isFavorite={isPresentInFavorites(el.id)}
              />
            ))}
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: "50px" }}>
          <TitleSection title="Tartare" />
          <Stack alignItems="center" sx={{paddingBottom: '20px'}}>
          <Typography
              variant="body1"
              sx={{ fontWeight: "100", width: "80%", textAlign: "center" }}
            >
              {i18next.t("servedMontanarina")}
            </Typography>
          </Stack>
          <Stack gap="15px">
            {(activeLocale === 'it' ? TARTARE : TARTARE_EN).map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                key={`entree${index}`}
                clickFavorites={handleFavorite}
                isFavorite={isPresentInFavorites(el.id)}
              />
            ))}
          </Stack>
        </Stack>
      </PageContainer>
    </Page>
  );
};

export default EntreeTartare;

import { Page, PageContainer } from "components";
import { InfoContainer, Line, NewYearContainer, Shape } from "./style";
import shape from "assets/newyear/Shape.png";
import { Divider, Stack, Typography } from "@mui/material";

const NewYearPage = () => {
  return (
    <Page title="Capodanno da Mamà">
      <NewYearContainer>
        <Shape>
          <img src={shape} alt="shape" />
        </Shape>
        <InfoContainer>
          <Stack alignItems="center">
            <Typography
              fontSize="80px"
              lineHeight="50px"
              fontWeight="100"
              fontFamily="Mamà Cursive"
            >
              Capodanno
            </Typography>
            <Typography
              fontSize="40px"
              lineHeight="30px"
              fontWeight="100"
              fontFamily="Mamà Cursive"
            >
              da
            </Typography>

            <Typography
              fontSize="80px"
              lineHeight="70px"
              fontWeight="100"
              fontFamily="Mamà Cursive"
            >
              Mamà
            </Typography>
          </Stack>
          <Typography
            fontSize="50px"
            fontWeight="100"
            fontFamily="Mamà Cursive"
          >
            Menù
          </Typography>

          <Stack alignItems="center">
            <Typography
              fontSize="40px"
              fontWeight="100"
              fontFamily="Mamà Cursive"
            >
              Entreè
            </Typography>
            <Typography variant="body1" fontWeight="400" textAlign="center">
              Pettole con crema di lenticchie rosse, cotechino croccante e
              polvere d’arancia
            </Typography>
          </Stack>
          <Stack alignItems="center">
            <Typography
              fontSize="40px"
              fontWeight="100"
              fontFamily="Mamà Cursive"
            >
              Starters
            </Typography>
            <Stack gap="10px">
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Crostini di polenta, lardo di Patanegra, tomino fondente e noci
              </Typography>
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Parmigiana di gobbi marchigiana
              </Typography>
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Arrosto di maialino in porchetta della Casa
              </Typography>
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Olive ascolane al tartufo e creme fritte della tradizione
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems="center">
            <Typography
              fontSize="40px"
              fontWeight="100"
              fontFamily="Mamà Cursive"
            >
              Main
            </Typography>
            <Stack gap="10px">
              <Typography variant="body1" fontWeight="400" textAlign="center">
                La nostra selezione di lievitati
              </Typography>
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Ricotta cremosa di bufala, pesto di avocado e mandorle, fiocco di
                prosciutto crudo
              </Typography>
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Sfilacci di cappone, maionese allo zafferano, crumble di castagne
              </Typography>
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Crema di zucca, mozzarella fiordilatte, erbe di campo ripassate,
                ciauscolo di Norcia IGP, briciole di tarallo pugliese
              </Typography>
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Fonduta di pecorino romano DOP, guanciale croccante, carbocrema,
                tartufo nero estivo, pepe in grani
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems="center">
            <Typography
              fontSize="40px"
              fontWeight="100"
              fontFamily="Mamà Cursive"
            >
              Dessert
            </Typography>
            <Stack gap="10px">
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Panettone artigianale al cioccolato fondente e frutti esotici
                servito con il tipico torrone natalizio
              </Typography>
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Calice di bollicine per brindare insieme all’inizio del nuovo
                anno
              </Typography>
              <Typography variant="body1" fontWeight="400" textAlign="center">
                Caffè
              </Typography>
            </Stack>
          </Stack>
          <Line />
          <Stack alignItems="center">
            <Typography variant="body1" fontWeight="700" textAlign="center">
              LA CENA SARÀ ACCOMPAGNATA DA:
            </Typography>
            <Stack gap="0px">
              <Typography fontSize="14px" fontWeight="400" textAlign="center">
                “TERRAVIGNATA” - VERDICCHIO DI MATELICA DOC
              </Typography>
              <Typography fontSize="14px" fontWeight="400" textAlign="center">
                "TERRAVIGNATA” - ROSSO MARCHE IGT
              </Typography>
            </Stack>
          </Stack>
            <Line />
          <Stack alignItems="center">
          <Typography
              fontSize="80px"
              lineHeight="80px"
              fontWeight="100"
              fontFamily="Mamà Cursive"
            >
               € 60,00
            </Typography>
            <Typography
              fontSize="50px"
              lineHeight="0px"
              fontWeight="100"
              fontFamily="Mamà Cursive"
              sx={{marginBottom: '50px'}}
            >
              per persona
            </Typography>
          </Stack>
          <Line />
          <Stack alignItems="center">
            <Typography variant="body1" fontWeight="700" textAlign="center">
              LIVE DJ SET ALL NIGHT LONG BY:
            </Typography>
            <Stack gap="0px">
              <Typography fontSize="25px" fontWeight="100" textAlign="center">
                Roberto Dottori
              </Typography>
            </Stack>
          </Stack>
        </InfoContainer>
      </NewYearContainer>
    </Page>
  );
};

export default NewYearPage;

import { styled } from "@mui/material";

export const NewYearContainer = styled('div')({
    width: '100%',
    position: 'relative',
    top: '70px',
    padding: '10px',
    paddingBottom: '150px',
})

export const Shape = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.3,
    "& img": {
        width: '60%'
    }
})

export const InfoContainer = styled('div')({
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',
    padding: '10px',
    paddingTop: '50px',

})

export const Line = styled('div')({
    width: '100%',
    height: '1px',
    flexGrow: 2,
    backgroundColor: '#ffffff90',
})
import { CardActionArea, Typography } from "@mui/material"
import { Button, ButtonContainer, ScrollTopButtonContainer } from "./ScrollTopButton.style"
import { Icon } from "@iconify/react"
import { useEffect, useState } from "react"

const ScrollTopButton = () => {

    const [show, setShow] = useState<boolean>(false)


    useEffect(() => {
        window.addEventListener("scroll", setScroll);
    
        return () => {
          window.removeEventListener("scroll", setScroll);
        };
      }, []);
    
      //function
      const setScroll = () => {
        if (window.scrollY > 700) {
            setShow(true);
        } else {
            setShow(false);
        }
      };

    const onClick = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    return <ScrollTopButtonContainer show={show}>
        <ButtonContainer>
            <Button>
                <CardActionArea onClick={onClick} sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant="h5" lineHeight="0">
                        <Icon icon="akar-icons:chevron-up" />
                    </Typography>
                </CardActionArea>
            </Button>
        </ButtonContainer>
    </ScrollTopButtonContainer>
}

export {ScrollTopButton}
import { create } from "zustand";

interface IUseFavoritesProps {
  favorites: number[];
  addFavorites: (id: number) => void;
  removeFavorites: (id: number) => void;
  snackbar: ISnackbarHookProps;
  showSnackbar: (message: string) => void;
  hideSnackbar: VoidFunction;
  replaceFavorites: (favorites: number[]) => void;
}

interface ISnackbarHookProps {
  show: boolean;
  message: string | null;
}

const useFavorites = create<IUseFavoritesProps>((set) => ({
  favorites: [],
  snackbar: {
    show: false,
    message: null,
  },
  addFavorites: (id: number) => set((state) => ({ favorites: [...state.favorites, id] })),
  removeFavorites: (id: number) =>
    set((state) => {
      const filteredFavorites: number[] = state.favorites.filter((el) => el !== id);
      return {
        favorites: filteredFavorites,
      };
    }),
  showSnackbar: (message: string) => set(() => ({ snackbar: { show: true, message: message } })),
  hideSnackbar: () => set(() => ({ snackbar: { show: false, message: null } })),
  replaceFavorites: (favorites: number[]) => set(() => ({ favorites })),
}));

export { useFavorites };

import { Controller, useFormContext } from "react-hook-form";
import { TextField, Typography } from "@mui/material";
import { IRHFTextFieldProps } from "./rhf-textfield.types";

const RHFTextField = ({ name, rules, color = 'primary', variant = "outlined", ...other }: IRHFTextFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...other}
          error={Boolean(error)}
          color={color}
          variant={variant}
          helperText={ !!error ? 
            <Typography variant="caption" color="error">
              {error?.message}
            </Typography> : null
          }
        />
      )}
    />
  );
};

export { RHFTextField };

import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IRHFPasswordTextFieldProps } from "./rhf-password-textfield.types";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { EYE, EYE_CLOSE } from "constants/icons";

const RHFPasswordTextField = ({
  name,
  rules,
  label,
  variant = "outlined",
  ...other
}: IRHFPasswordTextFieldProps) => {
  const { control } = useFormContext();

  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          label={label}
          type={visible ? "text" : "password"}
          error={Boolean(error)}
          variant={variant}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setVisible(!visible)}>
                  <Icon icon={!visible ? EYE_CLOSE : EYE} />{" "}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={
            <Typography variant="caption" color="error">
              {error ? error.message : null}
            </Typography>
          }
          {...field}
          {...other}
        />
      )}
    />
  );
};

export { RHFPasswordTextField };

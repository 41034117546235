
import Router from "routes";
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import { Dialog, NewYearDialog,  Snackbar } from "components";
import { useFavorites } from "hooks/useFavorites";
import { useGlobalStore } from "stores/global";
import { ThemeProviderTwo } from "themes";

function App() {
  //hooks
  const { snackbar, hideSnackbar, replaceFavorites } = useFavorites();

  const { setActiveLocale } = useGlobalStore();



  useEffect(() => {
    const localFavorites: number[] = JSON.parse(
      localStorage.getItem("favorites") as string
    );
    if (localFavorites) replaceFavorites(localFavorites);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const parsedLocalActiveLocale = JSON.parse(
      localStorage.getItem("activeLocaleMama") as string
    );

    if (parsedLocalActiveLocale) {
      setActiveLocale(parsedLocalActiveLocale);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    <ThemeProviderTwo>
      <BrowserRouter>
        <Router />
        <Dialog />
        <NewYearDialog />
        {/* <Welcome /> */}
        <Snackbar
          message={snackbar.message}
          open={snackbar.show}
          onClose={hideSnackbar}
        />
      </BrowserRouter>
    </ThemeProviderTwo>
  );
}

export default App;

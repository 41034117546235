export const addToLocalStorage = (id: number) => {
  const localFavorites = JSON.parse(localStorage.getItem("favorites") as string);

  if (localFavorites) {
    const isPresent = localFavorites.find((el: number) => el === id);
    if (isPresent) {
      const newFavorites = localFavorites.filter((el: number) => el !== id);
      localStorage.setItem("favorites", JSON.stringify(newFavorites));
      return;
    }
    if (!isPresent) {
      localStorage.setItem("favorites", JSON.stringify([...localFavorites, id]));
      return;
    }
    return;
  }
  localStorage.setItem("favorites", JSON.stringify([id]));
};

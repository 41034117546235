import { Rating, styled } from "@mui/material";

export const StyledRating = styled(Rating)<{severity: number}>(({severity, theme}) => ({
    "&.Mui-disabled": {
        opacity: 1,
    },
    '& .MuiRating-iconFilled': {
        color: severity < 2 ? theme.palette.success.main : severity > 5 ? theme.palette.error.main : theme.palette.warning.main
      },
      '& .MuiRating-iconHover': {
        color: severity < 2 ? theme.palette.success.main : severity > 5 ? theme.palette.error.main : theme.palette.warning.main
      },
}))
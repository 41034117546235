import { Stack } from "@mui/material";
import { Card, Page, PageContainer, TitleSection } from "components";
import {
  IFoodProps,
  PIZZE_LE_CONTEMPORANEE,
  PIZZE_LE_TRADIZIONALI,
} from "constants/food";
import {
  PIZZE_LE_CONTEMPORANEE_EN,
  PIZZE_LE_TRADIZIONALI_EN,
} from "constants/foodEn";
import { useFavorites } from "hooks/useFavorites";
import useTranslation from "i18n";
import { useGlobalStore } from "stores/global";
import { addToLocalStorage } from "utils/localStorage";

const Pizze = () => {
  //hooks
  const { favorites, addFavorites, removeFavorites, showSnackbar } =
    useFavorites();
  const { i18next } = useTranslation();
  const { activeLocale } = useGlobalStore();
  //function
  const handleFavorite = (id: number, name: string) => {
    const isPresent = favorites.find((el) => el === id);
    if (Boolean(isPresent)) {
      showSnackbar(`${name} rimosso dai preferiti`);
      removeFavorites(id);
      addToLocalStorage(id);
      return;
    }
    if (!Boolean(isPresent)) {
      showSnackbar(`${name} aggiunto ai preferiti`);
      addFavorites(id);
      addToLocalStorage(id);
      return;
    }
  };

  const isPresentInFavorites = (id: number) => {
    const isPresent = favorites.find((el) => el === id);
    return Boolean(isPresent);
  };
  return (
    <Page title={i18next.t("head-pizza")}>
      <PageContainer>
        <Stack sx={{ marginTop: "20px" }}>
          <TitleSection title={i18next.t("contemporanee")} />
          <Stack gap="15px">
            {(activeLocale === "it"
              ? PIZZE_LE_CONTEMPORANEE
              : PIZZE_LE_CONTEMPORANEE_EN
            ).map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                key={`cont${index}`}
                clickFavorites={handleFavorite}
                isFavorite={isPresentInFavorites(el.id)}
              />
            ))}
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: "50px" }}>
          <TitleSection title={i18next.t("tradizionali")} />
          <Stack gap="15px">
            {(activeLocale === "it"
              ? PIZZE_LE_TRADIZIONALI
              : PIZZE_LE_TRADIZIONALI_EN
            ).map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                key={`trad${index}`}
                clickFavorites={handleFavorite}
                isFavorite={isPresentInFavorites(el.id)}
              />
            ))}
          </Stack>
        </Stack>
      </PageContainer>
    </Page>
  );
};

export default Pizze;

import { Slide, Typography } from "@mui/material";
import { StyledSnackbar } from "./Snackbar.style";
import { ISnackbarProps } from "./Snackbar.types";
import { Icon } from "@iconify/react";
import { Stack } from "@mui/material";

const Snackbar = (props: ISnackbarProps) => {
  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionComponent={Slide}
      autoHideDuration={2000}
      {...props}
      message={
        <Stack flexDirection="row" gap="10px" alignItems="center">
          <Icon icon="ic:round-star" style={{ fontSize: "20px" }} />
          <Typography sx={{ fontSize: "16px", lineHeight: 0, position: "relative", top: "1px" }}>
            {props.message}
          </Typography>
        </Stack>
      }
    />
  );
};

export { Snackbar };

import { Icon } from "@iconify/react";
import { Logo } from "assets";

import { useHeader } from "hooks";
import { useEffect, useState } from "react";

import {
  BurgerLine,
  HeaderContainer,
  IconContainer,
  LogoContainer,
  StyledIconButton,
} from "./Header.style";
import { useNavigate } from "react-router-dom";
import { NEWYEAR } from "constants/paths";

const Header = () => {
  //hooks
  const {
    sideMenu,
    openSideMenu,
    closeSideMenu,
    searchDrawer,
    openSearchDrawer,
    closeSearchDrawer,
  } = useHeader();
  const navigate = useNavigate()

  //state
  const [offset, setOffset] = useState<boolean>(false);

  //effect
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    });
  }, []);

  return (
    <HeaderContainer offset={offset}>
      <IconContainer position="left">
        <StyledIconButton onClick={() => (sideMenu ? closeSideMenu() : openSideMenu())}>
          <BurgerLine open={sideMenu} />
          <BurgerLine open={sideMenu} />
          <BurgerLine open={sideMenu} />
        </StyledIconButton>
      </IconContainer>
      <LogoContainer>
        <Logo width={offset ? "40px" : "60px"} />
      </LogoContainer>
      <IconContainer position="right">
        {/* <StyledIconButton onClick={() => (searchDrawer ? closeSearchDrawer() : openSearchDrawer())}>
          <Icon icon={searchDrawer ? "ri:search-fill" : "ri:search-line"} />
        </StyledIconButton> */}
        <StyledIconButton onClick={() => {navigate(NEWYEAR)}} isNewYear >
          <Icon icon="file-icons:wine" fontSize="50px" />
        </StyledIconButton>
      </IconContainer>
    </HeaderContainer>
  );
};

export { Header };

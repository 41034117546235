/* eslint-disable jsx-a11y/img-redundant-alt */
import { ButtonClose, GlassesContainer, InfoContainer, ShapeOne, ShapeTwo, Sparkles, StyledDialog } from "./NewYearDialog.style"
import back from 'assets/newyear/back.jpg'
import shapeone from 'assets/newyear/Shape.png'
import shapetwo from 'assets/newyear/Shape2.png'
import glasses from 'assets/newyear/glasses.png'
import sparkles from 'assets/newyear/sparkles.png'
import { Button, IconButton, Stack, Typography } from "@mui/material"
import { Icon } from '@iconify/react';
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useUserAgent } from "@oieduardorabelo/use-user-agent";
import { NEWYEAR } from "constants/paths"

const NewYearDialog = () => {
    const details = useUserAgent();
    const location = useLocation()
    const navigate = useNavigate()
    const [open, setOpen] = useState<boolean>(false)


    useEffect(() => {

        const newYearSession = sessionStorage.getItem('newYear')
        
       if (location.pathname !== '/' && !newYearSession && details?.device.type === "mobile" ) {
         // Timer per aprire la dialog dopo 2 secondi
         const timer = setTimeout(() => {
            setOpen(true);
          }, 2000);
      
          // Pulizia del timer in caso di smontaggio del componente
          return () => clearTimeout(timer);
       }
      }, [location]);


      const handleClose = () => {
        setOpen(false);
        sessionStorage.setItem('newYear', JSON.stringify(true))
      };

      const handleClick = () => {
        handleClose()
        navigate(NEWYEAR)
      }

    return <StyledDialog open={open}>
        <img src={back} className="background"  alt="image" />
        <ShapeOne>
            <img src={shapeone}  alt="image" />
        </ShapeOne>
        <ShapeTwo>
            <img src={shapetwo}  alt="image" />
        </ShapeTwo>
        <GlassesContainer>
            <img src={glasses}  alt="image" />
        </GlassesContainer>
        <Sparkles>
            <img src={sparkles} alt="image"  />
        </Sparkles>
        <InfoContainer>
            <Typography variant='body2' fontWeight="700">CAPODANNO DA MAMÁ</Typography>
            <Stack alignItems='center'>
                <Typography fontSize="100px" lineHeight="70px" fontWeight="100" fontFamily="Mamà Cursive">Brinda</Typography>
                <Typography fontSize="40px" lineHeight="10px" fontWeight="100" fontFamily="Mamà Cursive" sx={{marginTop: '15px'}}>al</Typography>
                <Typography fontSize="80px" lineHeight="70px" fontWeight="100" fontFamily="Mamà Cursive">Nuovo Anno</Typography>
                <Typography fontSize="70px" lineHeight="10px" fontWeight="100" fontFamily="Mamà Cursive" sx={{marginTop: '15px'}}>con noi!</Typography>
            </Stack>
           <Stack gap="10px">
           <Button sx={{marginTop: '30px', backgroundColor: '#CDA434', color: 'white'}} variant="contained" onClick={handleClick}>SCOPRI DI PIÙ</Button>
            <Button  variant="contained" onClick={handleClose}>CHIUDI</Button>

           </Stack>
        </InfoContainer>
        <ButtonClose>
            <IconButton sx={{color: 'white'}} onClick={handleClose}>
                <Icon icon="mingcute:close-fill"  />
            </IconButton>
        </ButtonClose>
    </StyledDialog>
}

export {NewYearDialog}
import { Logo } from "assets";
import { FormContainer, LoginContainer } from "./AuthLayout.style";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

const AuthLayout = () => {
  return (
    <LoginContainer>
      <FormContainer>
        <Logo width="60px" />
        <Box width="100%" alignItems='center'>
        <Outlet />
        </Box>
      </FormContainer>
    </LoginContainer>
  );
};

export { AuthLayout };

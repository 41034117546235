import { create } from "zustand";

interface IUseChangeThemeProps {
  isDark: boolean;
  toggleDarkMode: Function;
  changeToLocale: Function;
}

const useChangeTheme =
  create<IUseChangeThemeProps>((set) => ({
    isDark: true,
    toggleDarkMode: () =>
      set((state) => ({ isDark: !state.isDark })),
    changeToLocale: (isDark: boolean) =>
      set(() => ({ isDark })),
  }));

export { useChangeTheme };

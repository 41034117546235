import { Stack } from "@mui/material";
import {
  Card,
  MyButton,
  Page,
  PageContainer,
  ScrollTopButton,
  TitleSection,
} from "components";
import {
  BEVANDE,
  BIRRE_ARTIGIANLI,
  BOLLICINE,
  CHAMPAGNE,
  GIN_TONIC,
  IFoodProps,
  VINI_BIANCHI,
  VINI_ROSSI,
} from "constants/food";
import {
  BEVANDE_EN,
  BIRRE_ARTIGIANLI_EN,
  BOLLICINE_EN,
  CHAMPAGNE_EN,
  GIN_TONIC_EN,
  VINI_BIANCHI_EN,
  VINI_ROSSI_EN,
} from "constants/foodEn";
import { useFavorites } from "hooks/useFavorites";
import useTranslation from "i18n";
import { MutableRefObject, useRef } from "react";
import { useGlobalStore } from "stores/global";

const Bevande = () => {
  //hooks
  const { favorites, addFavorites, removeFavorites, showSnackbar } =
    useFavorites();

  //ref
  const bollicineRef = useRef<HTMLDivElement | null>(null);
  const champagneRef = useRef<HTMLDivElement | null>(null);
  const bianchiRef = useRef<HTMLDivElement | null>(null);
  const rossiRef = useRef<HTMLDivElement | null>(null);
  const bevandeRef = useRef<HTMLDivElement | null>(null);
  const beerRef = useRef<HTMLDivElement | null>(null);
  const tonicRef = useRef<HTMLDivElement | null>(null);
  //function
  const handleFavorite = (id: number, name: string) => {
    const isPresent = favorites.find((el) => el === id);
    if (Boolean(isPresent)) {
      showSnackbar(`${name} rimosso dai preferiti`);
      removeFavorites(id);
      return;
    }
    if (!Boolean(isPresent)) {
      showSnackbar(`${name} aggiunto ai preferiti`);
      addFavorites(id);
      return;
    }
  };

  const { i18next } = useTranslation();
  const { activeLocale } = useGlobalStore();
  const goTo = (ref: MutableRefObject<HTMLDivElement | null>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const isPresentInFavorites = (id: number) => {
    const isPresent = favorites.find((el) => el === id);
    return Boolean(isPresent);
  };
  return (
    <Page title={i18next.t("bevande-page")}>
      <PageContainer>
        <Stack gap="10px" sx={{ height: "85vh" }}>
          <MyButton
            onClick={() => {
              goTo(bollicineRef);
            }}
          >
            {i18next.t("bollicine")}
          </MyButton>
          <MyButton
            onClick={() => {
              goTo(champagneRef);
            }}
          >
            {i18next.t("champagne")}
          </MyButton>
          <MyButton
            onClick={() => {
              goTo(bianchiRef);
            }}
          >
            {i18next.t("vini-bianchi")}
          </MyButton>
          <MyButton
            onClick={() => {
              goTo(rossiRef);
            }}
          >
            {i18next.t("vini-rossi")}
          </MyButton>
          <MyButton
            onClick={() => {
              goTo(bevandeRef);
            }}
          >
            {i18next.t("bevande")}
          </MyButton>
          <MyButton
            onClick={() => {
              goTo(beerRef);
            }}
          >
            {i18next.t("birre")}
          </MyButton>
          <MyButton
            onClick={() => {
              goTo(tonicRef);
            }}
          >
            {i18next.t("cocktails")}
          </MyButton>
        </Stack>
        <Stack sx={{ paddingTop: "100px" }} ref={bollicineRef}>
          <TitleSection title={i18next.t("bollicine-title")} />
          <Stack gap="15px">
            {(activeLocale === "it" ? BOLLICINE : BOLLICINE_EN).map(
              (el: IFoodProps, index: number) => (
                <Card
                  {...el}
                  notTip={true}
                  key={`cont${index}`}
                  clickFavorites={handleFavorite}
                  isFavorite={isPresentInFavorites(el.id)}
                />
              )
            )}
          </Stack>
        </Stack>
        <Stack sx={{ paddingTop: "100px" }} ref={champagneRef}>
          <TitleSection title={i18next.t("champagne-title")} />
          <Stack gap="15px">
            {(activeLocale === "it" ? CHAMPAGNE : CHAMPAGNE_EN).map(
              (el: IFoodProps, index: number) => (
                <Card
                  {...el}
                  notTip={true}
                  key={`cont${index}`}
                  clickFavorites={handleFavorite}
                  isFavorite={isPresentInFavorites(el.id)}
                />
              )
            )}
          </Stack>
        </Stack>
        <Stack sx={{ paddingTop: "100px" }} ref={bianchiRef}>
          <TitleSection title={i18next.t("vini-bianchi-title")} />
          <Stack gap="15px">
            {(activeLocale === "it" ? VINI_BIANCHI : VINI_BIANCHI_EN).map(
              (el: IFoodProps, index: number) => (
                <Card
                  {...el}
                  notTip={true}
                  key={`cont${index}`}
                  clickFavorites={handleFavorite}
                  isFavorite={isPresentInFavorites(el.id)}
                />
              )
            )}
          </Stack>
        </Stack>
        <Stack sx={{ paddingTop: "100px" }} ref={rossiRef}>
          <TitleSection title={i18next.t("vini-rossi-title")} />
          <Stack gap="15px">
            {(activeLocale === "it" ? VINI_ROSSI : VINI_ROSSI_EN).map(
              (el: IFoodProps, index: number) => (
                <Card
                  {...el}
                  notTip={true}
                  key={`cont${index}`}
                  clickFavorites={handleFavorite}
                  isFavorite={isPresentInFavorites(el.id)}
                />
              )
            )}
          </Stack>
        </Stack>
        <Stack sx={{ paddingTop: "100px" }} ref={bevandeRef}>
          <TitleSection title={i18next.t("bevande-title")} />
          <Stack gap="15px">
            {(activeLocale === "it" ? BEVANDE : BEVANDE_EN).map(
              (el: IFoodProps, index: number) => (
                <Card
                  {...el}
                  notTip={true}
                  key={`cont${index}`}
                  clickFavorites={handleFavorite}
                  isFavorite={isPresentInFavorites(el.id)}
                />
              )
            )}
          </Stack>
        </Stack>
        <Stack sx={{ paddingTop: "100px" }} ref={beerRef}>
          <TitleSection title={i18next.t("birre-title")} />
          <Stack gap="15px">
            {(activeLocale === "it"
              ? BIRRE_ARTIGIANLI
              : BIRRE_ARTIGIANLI_EN
            ).map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                notTip={true}
                key={`cont${index}`}
                clickFavorites={handleFavorite}
                isFavorite={isPresentInFavorites(el.id)}
              />
            ))}
          </Stack>
        </Stack>
        <Stack sx={{ paddingTop: "100px" }} ref={tonicRef}>
          <TitleSection title=   {i18next.t("cocktails-title")} />
          <Stack gap="15px">
            {(activeLocale === 'it' ? GIN_TONIC : GIN_TONIC_EN).map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                notTip={true}
                key={`cont${index}`}
                clickFavorites={handleFavorite}
                isFavorite={isPresentInFavorites(el.id)}
              />
            ))}
          </Stack>
        </Stack>
        <ScrollTopButton />
      </PageContainer>
    </Page>
  );
};

export default Bevande;

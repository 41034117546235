import { Stack, Typography } from "@mui/material";
import { TitleContainer, DottedContainer } from "./TitleSection.style";
import { ITitleSectionProps } from "./TitleSection.types";

const TitleSection = ({ title, subtitle }: ITitleSectionProps) => {
  return (
    <Stack sx={{ paddingBottom: "30px" }}>
      <TitleContainer>
        <DottedContainer />
        <Typography variant="h4" sx={{ lineHeight: "12px", fontSize: '45px', whiteSpace: "noWrap", fontFamily: 'Mamà Eyesome'}}>
          {title}
        </Typography>
        <DottedContainer />
      </TitleContainer>
      {subtitle && (
        <Typography
          variant="body1"
          sx={{
            lineHeight: "18px",
            fontWeight: "300",
            width: "100%",
            textAlign: "center",
            paddingBottom: "30px",
          }}
        >
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};

export { TitleSection };

import { styled } from "@mui/material";

export const TitleContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: 'center',
  paddingTop: '20px',
  paddingBottom: "20px",
  gap: "5px",
}));

export const DottedContainer = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  borderBottom: `1px solid ${
    theme.palette.mode === "light" ? theme.palette.grey[700] : theme.palette.grey[300]
  }`,
}));

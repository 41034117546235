import { styled, alpha } from "@mui/material";

export const LoadingContainer = styled("div")(
  ({ theme }) => ({
    height: "100vh",
    width: "100%",
    backgroundColor: alpha(
      theme.palette.grey[800],
      0.8
    ),
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(8px)",
  })
);

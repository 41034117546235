
import { defaultLang } from "config";
import { useCallback } from "react";
import { Locales } from "type/intl";
import { IGlobalStore } from "type/store";
import { create } from "zustand";

const useStore = create<IGlobalStore>(
  (set): IGlobalStore => ({
    activeLocale: defaultLang.value,
    requestedLocation: null,
    setActiveLocale: (newLocale: Locales) => {
      set({ activeLocale: newLocale });
    },
  })
);

const useGlobalSelector = () => useStore(useCallback((state) => state, []));

export { useGlobalSelector, useStore as useGlobalStore };

import {FormProvider as RHFFormProvider} from 'react-hook-form'
import { IFormProviderProps } from './form-provider.types'


const FormProvider = ({children, methods, onSubmit, ...other}: IFormProviderProps) => {
    return (
        <RHFFormProvider {...methods} {...other}>
            <form onSubmit={onSubmit} noValidate {...other}>
                {children}
            </form>
        </RHFFormProvider>
    )
}

export {FormProvider}
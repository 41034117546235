import * as PATHS from "./paths";

export interface IAppBarMenuProps {
  id: number;
  label: string;
  icon: string;
  url: string;
  value: string;
}

export interface IDrinkIconRef {
  ref: string;
  icon: string;
}

export const AppBarMenu: IAppBarMenuProps[] = [
  {
    id: 1,
    label: "Entrèe & Tartare",
    icon: "icon-park-solid:knife-fork",
    url: PATHS.ENTREE_E_TARTARE,
    value: "entreetartare",
  },
  {
    id: 2,
    label: "Pizze",
    icon: "ion:pizza-sharp",
    url: PATHS.PIZZE,
    value: "pizze",
  },
  {
    id: 5,
    label: "Pizze Senza Glutine",
    icon: "ion:pizza-outline",
    url: PATHS.FRITTI,
    value: "fritti",
  },
  {
    id: 3,
    label: "Sei Spicchi",
    icon: "game-icons:full-pizza",
    url: PATHS.SEI_SPICCHI,
    value: "seispicchi",
  },
  {
    id: 6,
    label: "Dolci",
    icon: "ph:bowl-food-fill",
    url: PATHS.DOLCI,
    value: "dolci",
  },
  {
    id: 7,
    label: "Bevande",
    icon: "fluent:drink-wine-20-filled",
    url: PATHS.BEVANDE,
    value: "bevande",
  },
];

export const AppBarMenEn: IAppBarMenuProps[] = [
  {
    id: 1,
    label: "Starter & Tartare",
    icon: "icon-park-solid:knife-fork",
    url: PATHS.ENTREE_E_TARTARE,
    value: "entreetartare",
  },
  {
    id: 2,
    label: "Pizzas",
    icon: "ion:pizza-sharp",
    url: PATHS.PIZZE,
    value: "pizze",
  },
  {
    id: 5,
    label: "Gluten-Free Pizzas",
    icon: "ion:pizza-outline",
    url: PATHS.FRITTI,
    value: "fritti",
  },
  {
    id: 3,
    label: "Sei Spicchi",
    icon: "game-icons:full-pizza",
    url: PATHS.SEI_SPICCHI,
    value: "seispicchi",
  },
  {
    id: 6,
    label: "Desserts",
    icon: "ph:bowl-food-fill",
    url: PATHS.DOLCI,
    value: "dolci",
  },
  {
    id: 7,
    label: "Drinks",
    icon: "fluent:drink-wine-20-filled",
    url: PATHS.BEVANDE,
    value: "bevande",
  },
];

export const DrinkIcon: IDrinkIconRef[] = [
  {
    ref: "wineBottle",
    icon: "mdi:bottle-wine",
  },
  {
    ref: "spumanteGlass",
    icon: "ph:champagne-duotone",
  },
  {
    ref: "wineGlassWhite",
    icon: "ph:wine",
  },
  {
    ref: "wineGlassRed",
    icon: "ph:wine-fill",
  },
  {
    ref: "waterIcon",
    icon: "material-symbols:water-drop-rounded",
  },
  {
    ref: "wineJugSmall",
    icon: "game-icons:jug",
  },
  {
    ref: "wineJugBig",
    icon: "game-icons:jug",
  },
  {
    ref: "beerGlass",
    icon: "tabler:beer-filled",
  },
  {
    ref: "beerGlass",
    icon: "ph:beer-stein-fill",
  },
  {
    ref: "pepsiBottle",
    icon: "mdi:bottle-soda-classic",
  },
  {
    ref: "bottleBeer",
    icon: "mdi:bottle-soda",
  },
  {
    ref: "cocktailGlass",
    icon: "bxs:drink",
  },
];

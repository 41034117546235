import { styled, alpha } from "@mui/material";

export const CardContainer = styled("div")(({ theme }) => ({
  width: "100%",
  backgroundImage: `radial-gradient(circle at 0%, ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : theme.palette.grey[50]
  }, ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[50]
  })`,
  borderRadius: "20px",
  padding: "25px",
  boxShadow: `0px 5px 10px #00000030, inset 0px 0px 50px ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[50]
  }`,
  borderLeft: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[50]
  }`,
  borderRight: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : theme.palette.grey[50]
  }`,
  borderTop: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : theme.palette.grey[50]
  }`,
  borderBottom: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[50]
  }`,
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  position: "relative",
  overflow: "hidden",
}));

export const IngredientsContainer = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  paddingLeft: "5px",
  gap: 3,
});

export const LineCard = styled("div")(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: alpha(
    theme.palette.mode === "light"
      ? theme.palette.grey[900]
      : theme.palette.grey[50],
    0.5
  ),
  display: "flex",
  flex: "1 1 0",
}));

export const SinglePriceContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  position: "relative",
  zIndex: 0,
  gap: "5px 5px",
  transition: "all 0.5s ease",
}));

export const LikeBox = styled("div")(({ showLike }: { showLike: boolean }) => ({
  height: "100%",
  width: "100%",
  backgroundColor: "#00000090",
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  borderRadius: "20px",
  justifyContent: "center",
  opacity: showLike ? 1 : 0,
  transition: "all 0.5s ease",
  zIndex: 1,
}));

export const LineCardSmall = styled("div")(({ theme }) => ({
  height: "1px",
  width: "10%",
  backgroundColor: alpha(
    theme.palette.mode === "light"
      ? theme.palette.primary.main
      : theme.palette.primary.main,
    0.5
  ),
  display: "flex",
}));

export const AllergenesContainer = styled("div")({
  width: "100%",
  position: "absolute",
  bottom: -5,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

import { Dialog, styled } from "@mui/material";

export const StyledDialog = styled(Dialog)({
    zIndex: "9999999999999",
    "&.MuiDialog-root .MuiPaper-root": {
        height: "90%",
        width: '95%',
        position: 'relative',
        overflow: 'hidden',
        "& .background": {
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        }
    }
})

export const ShapeOne = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '40%',
})

export const ShapeTwo = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    width: '15%',
})

export const GlassesContainer = styled('div')({
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    "& img": {
        width: '50%',
        position: 'relative',
        left: '7%'
    }
})

export const Sparkles = styled('div')({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.5,
    "& img": {
        height: '90%',
        width: '90%',
        objectFit: 'contain'
    }
})

export const InfoContainer = styled('div')({
    height: '100%',
    width: '100%',
    padding: '10px',
    paddingBottom: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
})

export const ButtonClose = styled('div')({
    position: 'absolute',
    top: '10px',
    right: '10px',
})
import { styled, alpha } from "@mui/material";

export const ButtonContainer = styled("a")(({ theme }) => ({
  height: "calc(55vh / 6)",
  maxHeight: "80px",
  width: "100%",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[50],
  borderRadius: "20px",
  boxShadow: `0px 5px 10px #00000030, inset 0px 0px 50px ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[50]
  }`,
  borderLeft: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[50]
  }`,
  borderRight: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : theme.palette.grey[50]
  }`,
  borderTop: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : theme.palette.grey[50]
  }`,
  borderBottom: `2px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[50]
  }`,
  transition: "all 0.3s ease",
  transform: "scale(1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  color: "white",
  overflow: "hidden",
  "&:hover": {
    backgroundColor: alpha(
      theme.palette.mode === "dark"
        ? theme.palette.grey[600]
        : theme.palette.grey[50],
      0.5
    ),
  },
  "&:active": {
    boxShadow: `0px 0px 5px #00000030, inset 0px 0px 50px ${
      theme.palette.mode === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[50]
    }`,
    transform: "scale(0.95)",
  },
}));

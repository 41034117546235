import { useUserAgent } from "@oieduardorabelo/use-user-agent";
import { NotInLocal } from "components";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const NotInLocalLayout = ({ children }: Props) => {
  const details = useUserAgent();
  return details?.device.type === "mobile" ? <>{children}</> : <NotInLocal />;
};

export { NotInLocalLayout };

import { create } from "zustand";

interface IUseHeaderProps {
  sideMenu: boolean;
  searchDrawer: boolean;
  openSideMenu: Function;
  closeSideMenu: Function;
  openSearchDrawer: Function;
  closeSearchDrawer: Function;
}

const useHeader = create<IUseHeaderProps>((set) => ({
  sideMenu: false,
  openSideMenu: () => set(() => ({ sideMenu: true, searchDrawer: false })),
  closeSideMenu: () => set(() => ({ sideMenu: false })),
  searchDrawer: false,
  openSearchDrawer: () => set(() => ({ searchDrawer: true, sideMenu: false })),
  closeSearchDrawer: () => set(() => ({ searchDrawer: false })),
}));

export { useHeader };

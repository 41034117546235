import * as PATHS from "constants/paths";
import { useRoutes } from "react-router-dom";
import { AuthLayout, PublicLayout } from "layouts";
import * as Page from "pages";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Page.SelectLanguages />,
    },
    {
      path: PATHS.PUBLIC,
      element: <PublicLayout withoutHeader />,
      children: [
        {
          path: PATHS.HOME,
          element: <Page.Home />,
        },
      ],
    },
    {
      path: PATHS.PUBLIC,
      element: <PublicLayout />,
      children: [
        {
          path: PATHS.ENTREE_E_TARTARE,
          element: <Page.EntreeTartare />,
        },
        {
          path: PATHS.SEI_SPICCHI,
          element: <Page.SeiSpicchi />,
        },
        {
          path: PATHS.PIZZE,
          element: <Page.Pizze />,
        },
        {
          path: PATHS.DOLCI,
          element: <Page.Dolci />,
        },
        {
          path: PATHS.FRITTI,
          element: <Page.Fritti />,
        },
        {
          path: PATHS.BEVANDE,
          element: <Page.Bevande />,
        },
        {
          path: PATHS.FAVORITE,
          element: <Page.Favorite />,
        },
        {
          path: PATHS.ALLERGENES,
          element: <Page.Allergenes />,
        },
        {
          path: PATHS.NEWYEAR,
          element: <Page.NewYearPage />
        }
      ],
    },
    {
      path: PATHS.AUTH,
      element: <AuthLayout />,
      children: [
        {
          path: PATHS.AUTH_LOGIN,
          element: <Page.Login />,
        },
      ],
    },
  ]);
}

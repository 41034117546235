import { IconButton, keyframes, styled, Theme } from "@mui/material";

export const HeaderContainer = styled("header")(
  ({ theme, offset }: { theme?: Theme; offset: boolean }) => ({
    height: offset ? 60 : 80,
    width: "100%",
    backgroundColor:
      theme?.palette.mode === "dark"
        ? theme.palette.grey[800]
        : theme?.palette.grey[50],
    position: "fixed",
    top: 0,
    left: 0,
    transition:
      "all .5s cubic-bezier(0.4, 0, 0.2, 1), background-color 0s 0s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999999",
  })
);

export const IconContainer = styled("div")(
  ({ position }: { position: "right" | "left" }) => ({
    height: "100%",
    position: "absolute",
    top: 0,
    left: position === "left" ? 10 : "auto",
    right: position === "right" ? 10 : "auto",
    display: "flex",
    alignItems: "center",
  })
);
export const LogoContainer = styled("div")({
  height: "100%",
  display: "flex",
  alignItems: "center",
});

const bounceWithPause = keyframes`
  0% {
    transform: translateY(-5px);
  }
    15% {
    transform: translateY(5px);

    }
    30% {
    transform: translateY(-5px);

    }
  45% {
    transform: translateY(0px);
  }
  70% {
    transform: translateY(0px); /* Pausa */
  }
  100% {
    transform: translateY(0px); /* Resta fermo */
  }
`;

export const StyledIconButton = styled(IconButton)<{ isNewYear?: boolean }>(
  ({ isNewYear }) => ({
    display: "flex",
    flexDirection: "column",
    height: isNewYear ? "50px" : "40px",
    width: isNewYear ? "50px" : "40px",
    color: isNewYear ? '#CDA434' : 'auto',
    gap: "5px",
    animation: isNewYear
    ? `${bounceWithPause} 1.5s ease-in-out infinite alternate`
    : "none",
  })
);

export const BurgerLine = styled("div")(
  ({ theme, open }: { theme?: Theme; open: boolean }) => ({
    height: "2px",
    width: "25px",
    backgroundColor:
      theme?.palette.mode === "dark"
        ? theme?.palette.grey[50]
        : theme?.palette.grey[600],
    position: "relative",
    top: 0,
    "&:first-of-type": {
      rotate: open ? "45deg" : 0,
      top: open ? 7 : 0,
      transition: open
        ? "all .5s .5s cubic-bezier(0.4, 0, 0.2, 1), background-color 0s 0s ease"
        : "all .5s 0s cubic-bezier(0.4, 0, 0.2, 1), background-color 0s 0s ease",
    },
    "&:nth-of-type(2)": {
      width: open ? 0 : "25px",
      transition: open
        ? "all .5s 0s cubic-bezier(0.4, 0, 0.2, 1), background-color 0s 0s ease"
        : "all .5s .5s cubic-bezier(0.4, 0, 0.2, 1), background-color 0s 0s ease",
    },
    "&:last-of-type": {
      rotate: open ? "-45deg" : 0,
      top: open ? -7 : 0,
      transition: open
        ? "all .5s .5s cubic-bezier(0.4, 0, 0.2, 1), background-color 0s 0s ease"
        : "all .5s 0s cubic-bezier(0.4, 0, 0.2, 1), background-color 0s 0s ease",
    },
  })
);

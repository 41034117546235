import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import { Page, PageContainer, TitleSection } from "components";
import useTranslation from "i18n";

const Allergenes = () => {

  const {i18next} = useTranslation()

  return (
    <Page title="Allergeni">
      <PageContainer>
        <TitleSection title="Allergeni" />
        <Typography variant="body1" sx={{ textAlign: "center" }}>
        {i18next.t('a-list')}        </Typography>
        <Stack gap="10px" sx={{ width: "100%", marginTop: "30px" }}>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              1.
            </Typography>
            <Typography variant="body1">
          {i18next.t('a-1')}
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              2.
            </Typography>
            <Typography variant="body1">   {i18next.t('a-2')}</Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              3.
            </Typography>
            <Typography variant="body1">   {i18next.t('a-3')}</Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              4.
            </Typography>
            <Typography variant="body1">   {i18next.t('a-4')}</Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              5.
            </Typography>
            <Typography variant="body1">   {i18next.t('a-5')}</Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              6.
            </Typography>
            <Typography variant="body1">   {i18next.t('a-6')}</Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              7.
            </Typography>
            <Typography variant="body1">
            {i18next.t('a-7')}
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              8.
            </Typography>
            <Typography variant="body1">
            {i18next.t('a-8')}            </Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              9.
            </Typography>
            <Typography variant="body1">  {i18next.t('a-9')}</Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              10.
            </Typography>
            <Typography variant="body1">  {i18next.t('a-10')}</Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              11.
            </Typography>
            <Typography variant="body1">
            {i18next.t('a-11')}
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              12.
            </Typography>
            <Typography variant="body1">
            {i18next.t('a-12')}            </Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              13.
            </Typography>
            <Typography variant="body1">  {i18next.t('a-13')}</Typography>
          </Stack>
          <Stack flexDirection="row" gap="10px" sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ fontWeight: "700" }}>
              14.
            </Typography>
            <Typography variant="body1">  {i18next.t('a-14')}</Typography>
          </Stack>
        </Stack>
        <Typography
          variant="body1"
          sx={{ fontSize: "700", width: "100%", textAlign: "center", marginTop: "30px" }}
        >
                  {i18next.t('a-info')}     
        </Typography>
      </PageContainer>
    </Page>
  );
};

export default Allergenes;

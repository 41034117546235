import { Icon } from "@iconify/react";
import { CardActionArea, Typography, useTheme } from "@mui/material";
import { AppBarMenEn, AppBarMenu, IAppBarMenuProps } from "constants/settings";
import { MouseEvent, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBarContainer,
  Line,
  LineContainer,
  StyledButton,
} from "./AppBar.style";
import gsap, { Power2 } from "gsap";
import { FAVORITE } from "constants/paths";
import { useGlobalStore } from "stores/global";

const AppBar = () => {
  //hooks
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { activeLocale } = useGlobalStore();
  //state
  const [line, setLine] = useState<number>(0);
  //ref
  const appBarRef = useRef(null);

  //function
  const handleButtonClick = (
    e: MouseEvent<HTMLDivElement>,
    url: string
  ): void => {
    setLine((e.currentTarget as HTMLDivElement).offsetLeft + 1);
    navigate(url);
  };

  useEffect(() => {
    gsap.fromTo(
      appBarRef.current,
      {
        y: 200,
        duration: 0.5,
        delay: 0.5,
      },
      {
        y: 0,
        duration: 0.5,
        delay: 0.5,
        ease: Power2.easeIn,
      }
    );
  }, []);

  return (
    <AppBarContainer ref={appBarRef} withShadow={pathname !== "/"}>
      {pathname !== "/" && (
        <LineContainer>
          <Line position={line} showLine={pathname !== FAVORITE} />
        </LineContainer>
      )}
      {(activeLocale === "it" ? AppBarMenu : AppBarMenEn).map(
        (el: IAppBarMenuProps, index: number) => (
          <StyledButton
            key={`appBar${index}`}
            onClick={(e: MouseEvent<HTMLDivElement>) =>
              handleButtonClick(e, el.url)
            }
            active={el.url === pathname}
          >
            <CardActionArea
              sx={{
                height: "100%",

                width: "100%",
                color:
                  palette.mode === "light"
                    ? palette.grey[800]
                    : palette.grey[50],
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                gap: "5px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  left: 0,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  icon={el.icon}
                  style={{
                    color:
                      palette.mode === "light"
                        ? el.url === pathname
                          ? palette.grey[800]
                          : palette.grey[600]
                        : el.url === pathname
                        ? palette.grey[50]
                        : palette.grey[400],
                    fontSize: "27px",
                  }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "5px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "calc(50% - 10px)",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "11px",
                    lineHeight: "12px",
                    width: "100%",
                    textAlign: "center",
                    left: 0,
                    color:
                      palette.mode === "light"
                        ? el.url === pathname
                          ? palette.grey[800]
                          : palette.grey[400]
                        : el.url === pathname
                        ? palette.grey[50]
                        : palette.grey[400],
                  }}
                >
                  {el.label}
                </Typography>
              </div>
            </CardActionArea>
          </StyledButton>
        )
      )}
    </AppBarContainer>
  );
};

export { AppBar };

import { Stack, Typography } from "@mui/material";
import { Card, Page, PageContainer, TitleSection } from "components";
import { IFoodProps, SEI_SPICCHI } from "constants/food";
import { SEI_SPICCHI_EN } from "constants/foodEn";
import { useFavorites } from "hooks/useFavorites";
import useTranslation from "i18n";
import { useGlobalStore } from "stores/global";

const SeiSpicchi = () => {
  //hooks
  const { favorites, addFavorites, removeFavorites, showSnackbar } = useFavorites();
  const {i18next} = useTranslation()
  const {activeLocale} = useGlobalStore()
  //function
  const handleFavorite = (id: number, name: string) => {
    const isPresent = favorites.find((el) => el === id);
    if (Boolean(isPresent)) {
      showSnackbar(`${name} rimosso dai preferiti`);
      removeFavorites(id);
      return;
    }
    if (!Boolean(isPresent)) {
      showSnackbar(`${name} aggiunto ai preferiti`);
      addFavorites(id);
      return;
    }
  };

  const isPresentInFavorites = (id: number) => {
    const isPresent = favorites.find((el) => el === id);
    return Boolean(isPresent);
  };
  return (
    <Page title={i18next.t('head-sei-spicchi')}>
      <PageContainer>
        <Stack sx={{ marginTop: "20px" }}>
          <TitleSection title={i18next.t('sei-spicchi')} />
          <Stack
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", paddingTop: "10px", paddingBottom: "40px" }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "100", width: "80%", textAlign: "center" }}
            >
            <div dangerouslySetInnerHTML={{__html: i18next.t('sei-spicchi-text')}} />
            </Typography>
          </Stack>
          <Stack gap="15px">
            {(activeLocale === 'it' ? SEI_SPICCHI : SEI_SPICCHI_EN).map((el: IFoodProps, index: number) => (
              <Card
                {...el}
                key={`trad${index}`}
                clickFavorites={handleFavorite}
                isFavorite={isPresentInFavorites(el.id)}
              />
            ))}
          </Stack>
        </Stack>
      </PageContainer>
    </Page>
  );
};

export default SeiSpicchi;

import {
  Stack,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
  Chip,
} from "@mui/material";
import {
  AllergenesContainer,
  CardContainer,
  IngredientsContainer,
  LineCard,
  SinglePriceContainer,
} from "./Card.style";
import { ICardProps } from "./Card.types";
import { IIngredientsProps, INGREDIENTS } from "constants/food";
import { Icon } from "@iconify/react";
import { takeIcon } from "utils/icon";
import { AppBarMenu, IAppBarMenuProps } from "constants/settings";
import { useGlobalStore } from "stores/global";
import { INGREDIENTS_EN } from "constants/foodEn";
import useTranslation from "i18n";
const Card = ({
  id,
  label,
  ingredients,
  singlePrice,
  pricesWord,
  secondLabel,
  pricesIconRef,
  pricesIconSize,
  category,
  priceWord,
  pricesUnit,
  clickFavorites,
  glutenFree,
  isFavorite,
  methods,
  allergenes,
  showCategory = false,
  showGlutenFree = false,
}: ICardProps) => {
  //hooks
  const { palette } = useTheme();
  const cardCategory: IAppBarMenuProps | undefined = AppBarMenu.find(
    (el) => el.id === category
  );
  const { activeLocale } = useGlobalStore();
  const { i18next } = useTranslation();
  //renders
  const renderIngredients = () => {
    const labelsIngredients: (IIngredientsProps | undefined)[] | undefined =
      ingredients?.map((i) =>
        (activeLocale === "it" ? INGREDIENTS : INGREDIENTS_EN).find(
          (el) => el.id === i
        )
      );
    return (
      <IngredientsContainer>
        {labelsIngredients?.map((el: IIngredientsProps | undefined) => (
          <Stack
            flexDirection="row"
            gap="5px"
            alignItems="center"
            key={`ingre${el?.id}`}
          >
            <Icon
              icon="material-symbols:line-start-square-rounded"
              style={{
                marginBottom: "3px",
                color:
                  palette.mode === "light"
                    ? palette.grey[900]
                    : palette.grey[50]
              }}
            />
            <Typography
              variant="caption"
              sx={{ lineHeight: "15px", fontWeight: "300" }}
            >
              {el?.label}
            </Typography>
          </Stack>
        ))}
      </IngredientsContainer>
    );
  };

  return (
    <CardContainer>
      <Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap="5px"
          sx={{ overflow: "hidden" }}
        >
          <Typography
            sx={{
              lineHeight: "26px",
              width: "auto",
              minWidth: "auto",
            }}
            variant="h4"
          >
            {label}
          </Typography>
          <LineCard />
        </Stack>
        <Stack flexDirection="row" gap="10px" alignItems="center">
          {showCategory ? (
            <Typography variant="caption">
              <Stack flexDirection="row" gap="10px" alignItems="center">
                <Icon
                  icon={cardCategory?.icon as string}
                  style={{
                    position: "relative",
                    bottom: "3px",
                    fontSize: "20px",
                  }}
                />
                {cardCategory?.label}
              </Stack>
            </Typography>
          ) : null}
          {showGlutenFree && glutenFree ? (
            <Chip
              color="warning"
              size="small"
              variant="outlined"
              label={i18next.t('gluternChip')}
            />
          ) : null}
        </Stack>
      </Stack>
      {methods ? (
        <Typography variant="caption">
          {activeLocale === "it" ? i18next.t("metodo") : null} {methods}{" "}
          {activeLocale === "en" ? i18next.t("metodo") : null}
        </Typography>
      ) : null}
      {secondLabel ? (
        <Typography variant="caption">{secondLabel}</Typography>
      ) : null}

      {ingredients && ingredients.length > 0 && renderIngredients()}
      {singlePrice ? (
        <SinglePriceContainer>
          <Tooltip
            title={
              isFavorite ? "Rimuovi dai Preferiti" : "Aggiungi ai Preferiti"
            }
            arrow
            placement="left"
          >
            <IconButton
              onClick={id ? () => clickFavorites?.(id, label) : () => {}}
              size="small"
              sx={{
                transition: "all 0.5s ease",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Icon
                icon={isFavorite ? "ic:round-star" : "ic:round-star-border"}
                style={{
                  fontSize: "20px",
                  color: !isFavorite
                    ? "currentColor"
                    : palette.mode === "dark"
                    ? "yellow"
                    : "orange",
                }}
              />
            </IconButton>
          </Tooltip>
          <LineCard />
          <Typography
            variant="h6"
            sx={{
              position: "relative",
              letterSpacing: "0.5px",
              fontWeight: '400',
              fontSize: "35px",
              fontFamily: "Mamà Cursive",
              bottom: "3px",
              lineHeight: 0,
            }}
          >
            {priceWord}
          </Typography>
          <AllergenesContainer>
            <Typography variant="caption" sx={{ fontSize: "10px" }}>
              {allergenes?.map((el, index) =>
                index === allergenes.length - 1 ? el : `${el} - `
              )}
            </Typography>
          </AllergenesContainer>
        </SinglePriceContainer>
      ) : (
        <Stack gap="5px">
          {pricesWord
            ?.map((el, index) => (
              <SinglePriceContainer key={`${id}${index}`}>
                {pricesUnit ? (
                  <Typography variant="caption">{pricesUnit[index]}</Typography>
                ) : null}
                <LineCard />

                <Typography
                  variant="h6"
                  sx={{
                    position: "relative",
                    letterSpacing: "0.5px",
                    fontSize: "35px",
                    fontFamily: "Mamà Cursive",
                    bottom: "3px",
                    lineHeight: 0,
                  }}
                >
                  {el}
                </Typography>
                {pricesIconRef?.[index] ? (
                  <Icon
                    style={{
                      fontSize: pricesIconSize?.[index],
                      position: "relative",
                      bottom: "2px",
                      margin: 0,
                      padding: 0,
                      width: "35px",
                      color:
                        palette.mode === "dark"
                          ? palette.grey[200]
                          : palette.grey[800],
                    }}
                    icon={takeIcon(pricesIconRef[index]) || ""}
                  />
                ) : null}
              </SinglePriceContainer>
            ))
            .reverse()}
        </Stack>
      )}
    </CardContainer>
  );
};

export { Card };

import { styled } from "@mui/material";

export const PlaceSettingContainer = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "flex-end",
  gap: "5px",
  paddingTop: "50px",
  paddingBottom: "100px",
});

export const PlaceSettingLine = styled("div")(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[50] : theme.palette.grey[800],
}));

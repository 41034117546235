import { styled, Drawer, Accordion } from "@mui/material";

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "&.MuiDrawer-root > .MuiPaper-root": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[50],
    width: "100%",
    position: "relative",
    opacity: 1,
    backgroundImage: "none",
    padding: "100px 10px 10px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export const StyledAccordion = styled(Accordion)(() => ({
  width: "100%",
}));

export const AdvancedFilterContainer = styled("div")({
  width: "100%",
  display: "inline-flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "flex-start",
  justifyContent: "flex-start",
});

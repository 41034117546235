import { useMemo } from "react";
import merge from "lodash/merge";

import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";

// system
import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";
// options
import { customShadows } from "./custom-shadows";
import { componentsOverrides } from "./overrides";
import { createPresets } from "./options/presets";
import { createContrast } from "./options/contrast";
import useThemeMode from "stores/theme";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

const ThemeProviderTwo = ({ children }: Props) => {
  const { themeMode } = useThemeMode();

  const presets = createPresets("default");

  const contrast = createContrast("default", themeMode);

  const memoizedValue = useMemo(
    () => ({
      palette: {
        ...palette(themeMode),
        ...presets.palette,
        ...contrast.palette,
      },
      customShadows: {
        ...customShadows(themeMode),
        ...presets.customShadows,
      },
      direction: "ltr",
      shadows: shadows(themeMode),
      shape: { borderRadius: 8 },
      typography,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [themeMode]
  );

  const theme = createTheme(memoizedValue as any);

  theme.components = merge(componentsOverrides(theme), contrast.components);

  return (
    <MuiThemeProvider theme={theme}>
   
        <CssBaseline />
        {children}
     
    </MuiThemeProvider>
  );
}


export {ThemeProviderTwo}
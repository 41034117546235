import { enUS, itIT } from "@mui/material/locale";
import { ILang, Locales } from "type/intl";


export const allLangs: ILang[] = [
  {
    label: "Italiano",
    value: Locales.IT,
    systemValue: itIT,
    icon: "emojione-v1:flag-for-italy",//addIcon
  },
  {
    label: "English",
    value: Locales.EN,
    systemValue: enUS,
    icon: "emojione-v1:flag-for-united-kingdom", //addIcon
  },

];

export const defaultLang = allLangs.find((lang: ILang) => lang.value === Locales.IT) || allLangs[0];
